import React, { useState } from 'react'
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Typography,
  Button
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import moment from 'moment'
import Loading from 'react-loader-spinner'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import history from '../../../history'
import SvgIcon from '../../common/SvgIcon'

const useStyles = makeStyles(theme => ({
  transcriptionName: {
    minWidth: '300px'
  },
  noWrap: {
    flexWrap: 'noWrap'
  },
  status: {
    display: 'inline-flex'
  },
  statusText: {
  },
  uploaded: {},
  edited: {
    color: theme.palette.primary.main
  },
  transcribed: {
    color: theme.palette.green.main
  },
  transcribing: {
    color: theme.palette.primary.main
  },
  errored: {
    color: theme.palette.error.main
  },
  bodyRow: {
    cursor: 'pointer'
  },
  disableClick: {
    cursor: 'default'
  },
  tableActions: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start'
  },
  button: {
    margin: theme.spacing(1)
  },
  rightIcon: {
    cursor: 'pointer',
    marginLeft: theme.spacing(1)
  }
}))

const TranscriptionsTable = ({
  transcriptions,
  transcribe,
  deleteFile,
  deleteTranscription,
  className
}) => {
  const classes = useStyles()
  return (
    <Paper className={className}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align='right'>Status</TableCell>
            <TableCell align='right'>Uploaded</TableCell>
            <TableCell align='right'>Last edited</TableCell>
            <TableCell align='right'>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transcriptions &&
            transcriptions.map(transcription => (
              <TableRow
                key={transcription.id}
                className={classNames(classes.bodyRow, !transcription.done && classes.disableClick)}
                onClick={() => {
                  if (transcription.done) {
                    history.push(`/transcriptions/${transcription.transcription_id}`)
                  }
                }}
              >
                <TableCell align='left' size='small' className={classes.transcriptionName}>
                  <Typography className={classes.noWrap}>{transcription.name}</Typography>
                </TableCell>
                <TableCell align='right' size='small'>
                  <TranscriptionStatus transcription={transcription} />
                </TableCell>
                <TableCell align='right' size='small'>
                  <Typography>{moment(transcription.timestamp).format('DD/MM/YYYY')}</Typography>
                </TableCell>
                <TableCell align='right' size='small'>
                  <Typography size='small'>
                    {transcription.edition_timestamp &&
                      moment(transcription.edition_timestamp).format('DD/MM/YYYY')}
                  </Typography>
                </TableCell>
                <TableCell align='right' size='small'>
                  {!transcription.transcription_id ? (
                    <React.Fragment>
                      <TranscribeButton
                        transcribe={transcribe}
                        fileId={transcription.id}
                      />
                      <Tooltip
                        title='Delete File'
                        onClick={event => {
                          event.stopPropagation()
                          if (window.confirm('Do you really want to delete the file?')) {
                            deleteFile(transcription.id)
                          }
                        }}
                      >
                        <Button variant='contained' color='secondary' className={classes.button}>
                          Delete
                          <DeleteIcon className={classes.rightIcon} />
                        </Button>
                      </Tooltip>
                    </React.Fragment>
                  ) : (
                      <Tooltip
                        title='Delete transcription'
                        onClick={event => {
                          event.stopPropagation()
                          if (window.confirm('Do you really want to delete the transcription?')) {
                            deleteTranscription(transcription.transcription_id)
                          }
                        }}
                      >
                        <Button variant='contained' color='secondary' className={classes.button}>
                          Delete
                          <DeleteIcon className={classes.rightIcon} />
                        </Button>
                      </Tooltip>
                    )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

const TranscribeButton = ({ transcribe, fileId }) => {
  const classes = useStyles()
  const [disabled, setDisabled] = useState(false)
  return (
    <Tooltip title='Transcribe' onClick={() => { setDisabled(true); transcribe(fileId) }}>
      <Button disabled={disabled} variant='contained' color='primary' className={classes.button}>
        {disabled ? 'Loading' : 'Transcribe'}
        <SvgIcon className={classes.rightIcon} icon='edit' />
      </Button>
    </Tooltip>
  )
}

const TranscriptionStatus = ({ transcription }) => {
  const classes = useStyles()

  if (!transcription.transcription_id) {
    return (
      <div className={classNames(classes.status, classes.uploaded)}>
        <SvgIcon icon='upload' />
        <Typography className={classNames(classes.statusText, classes.uploaded)}>
          Uploaded
        </Typography>
      </div>
    )
  } else if (transcription.edited) {
    return (
      <div className={classNames(classes.status, classes.edited)}>
        <SvgIcon icon='edit' />
        <Typography className={classNames(classes.statusText, classes.edited)}>Edited</Typography>
      </div>
    )
  } else if (transcription.done) {
    return (
      <div className={classNames(classes.status, classes.transcribed)}>
        <SvgIcon icon='check' />
        <Typography className={classNames(classes.statusText, classes.transcribed)}>
          Transcribed
        </Typography>
      </div>
    )
  } else {
    const dateCreated = moment(transcription.transcription_timestamp)
    const now = moment()
    const duration = moment.duration(now.diff(dateCreated))
    const hoursPassed = duration.asHours()

    if (hoursPassed < 4) {
      return (
        <div className={classNames(classes.status, classes.transcribing)}>
          <Loading type='Oval' width={20} height={20} color='#f1c73c' />
          <Typography className={classNames(classes.statusText, classes.transcribing)}>
            Transcribing
          </Typography>
        </div>
      )
    } else {
      return (
        <div className={classNames(classes.status, classes.errored)}>
          <SvgIcon icon='close' />
          <Typography className={classNames(classes.statusText, classes.errored)}>Error</Typography>
        </div>
      )
    }
  }
}

export default TranscriptionsTable

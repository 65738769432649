import React, { useEffect } from 'react'
import Page from '../../common/Page'
import {
  getFeeds
} from '../../../actions/reader'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Loading from '../../common/Loading'
import { Divider, Drawer, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const mapStateToProps = state => ({
  feeds: state.reader.feeds,
  isLoadingFeeds: state.reader.isLoadingFeeds
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getFeeds
    },
    dispatch
  )

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  toolbar: theme.mixins.toolbar,
  title: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1)
  },
  link: {
    textDecoration: 'none'
  }
}))

const Sidebar = ({ feeds, isLoadingFeeds, getFeeds }) => {
  const classes = useStyles()
  useEffect(() => {
    getFeeds()
  }, [])

  if (isLoadingFeeds) {
    return <Loading />
  }

  return (
    <Page>
      <Drawer
        className={classes.drawer}
        variant='permanent'
        classes={{
          paper: classes.drawerPaper
        }}
        anchor='left'
      >
        <div className={classes.toolbar} />
        <Typography align='center' className={classes.title} variant='h6' color='inherit' noWrap>
          Your Feeds
        </Typography>
        <Divider />
        <List>
          {feeds.map((feed) => (
            <Link to={`/reader/${feed.id}`} className={classes.link}>
              <ListItem button key={feed.name}>
                <ListItemText>
                  <Typography variant='subtitle1' noWrap>
                    {feed.name}
                  </Typography>
                </ListItemText>
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
    </Page >
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar)

import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router'
import List from './List'
import Transcription from './Transcription'

const mapStateToProps = state => ({
  loggedIn: state.auth && state.auth.loggedIn
})

const TranscriptionsRouter = ({ loggedIn }) => {
  if (!loggedIn) {
    return (
      <Redirect to={{
        pathname: '/',
        state: {from: '/transcriptions'}
      }} />
    )
  } else {
    return (
      <React.Fragment>
        <Route exact path='/transcriptions' component={List} />
        <Route exact path='/transcriptions/:transcriptionId' component={Transcription} />
      </React.Fragment>
    )
  }
}

export default connect(
  mapStateToProps
)(TranscriptionsRouter)

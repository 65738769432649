import React from 'react'
import { Router, Route } from 'react-router-dom'
import history from './history'
import Portal from './components/Portal'
import Transcriptions from './components/Transcriptions'
import theme from './theme'
import { ThemeProvider } from '@material-ui/styles'
import Reader from './components/Reader'
import Login from './components/Login'
import Notepad from './components/Notepad'
import SignUp from './components/SignUp'
import Reset from './components/Login/reset'
import Recover from './components/Login/recover'
import Create from './components/Login/create'

export default (
  <Router history={history}>
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Route exact path='/' component={Login} />
        <Route path='/portal' component={Portal} />
        <Route path='/transcriptions' component={Transcriptions} />
        <Route path='/reader' component={Reader} />
        <Route path='/notepad' component={Notepad} />
        <Route path='/confirmation/:confirmationId' component={SignUp} />
        <Route path='/reset/:resetId' component={Reset} />
        <Route path='/forgot-password' component={Recover} />
        <Route path='/register' component={Create} />
      </ThemeProvider>
    </React.Fragment>
  </Router>
)

import { NOTEPAD_SUCCESS } from './types'
import { BASE_URL } from '../config'
import { post } from '../services'

/*
 * Auth Actions
 */
export const translate = (input, inputLang, targetLang) => dispatch => {
  post(
    `${BASE_URL}/notepad/translate`,
    { input, targetLang, inputLang },
    dispatch,
    json => {
      console.log('success Translate')
      dispatch(notepadSuccess(json.result))
    },
    error => {
      console.log('error Translate', error)
      console.log(error)
    },
    false
  )
}

export const summarize = (input, inputLang, sentences) => dispatch => {
  post(
    `${BASE_URL}/notepad/summarize`,
    { input, inputLang, sentences },
    dispatch,
    json => {
      dispatch(notepadSuccess(json.result))
    },
    error => {
      console.log(error)
    },
    false
  )
}

export const notepadSuccess = (response) => {
  return {
    type: NOTEPAD_SUCCESS,
    response
  }
}

//- ----------------------------------
//- 💥 DISPLACY ENT
//- ----------------------------------

export default class displaCyENT {
  constructor(api, options) {
    this.api = api;
    this.token = options.token
    this.container = options.container;

    this.defaultText = options.defaultText || 'When Sebastian Thrun started working on self-driving cars at Google in 2007, few people outside of the company took him seriously.';
    this.defaultModel = options.defaultModel || 'en';
    this.defaultEnts = options.defaultEnts || ['per', 'org', 'gpe', 'loc', 'product'];

    this.onStart = options.onStart || false;
    this.onSuccess = options.onSuccess || false;
    this.onError = options.onError || false;
    this.onRender = options.onRender || false;

  }

  parse(text = this.defaultText, model = this.defaultModel, ents = this.defaultEnts, token) {
    if (typeof this.onStart === 'function') this.onStart();

    let request = new Request(this.api)
    let reqHeaders = new Headers({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    })
    let config = {
      method: 'POST',
      headers: reqHeaders,
      cache: 'default',
      body: JSON.stringify({ input: text, inputLang: model })
    }
    fetch(request, config)
      .then(response => {
        return response.json().catch(_ => response)
      })
      .then(json => {
        console.log('response', json)
        if (typeof this.onSuccess === 'function') this.onSuccess();
        this.render(text, json, ents, this.container);
      })
      .catch(error => {
        if (typeof this.onError === 'function') this.onError();
      })
  }

  render(text, spans, ents, container) {
    document.querySelector(container).innerHTML = ''
    let offset = 0;

    spans.forEach(({ label, start, end }) => {
      const entity = text.slice(start, end);
      const fragments = text.slice(offset, start).split('\n');

      fragments.forEach((fragment, i) => {
        document.querySelector(container).appendChild(document.createTextNode(fragment));
        if (fragments.length > 1 && i !== fragments.length - 1) document.querySelector(container).appendChild(document.createElement('br'));
      });

      if (ents.includes(label.toLowerCase())) {
        const mark = document.createElement('mark');
        let attribute = '';
        switch (label) {
          case 'PER':
            attribute = 'Person'
            break
          case 'LOC':
            attribute = 'LOC'
            break
          case 'ORG':
            attribute = 'ORG'
            break
          default:
            attribute = ''
            break
        }
        mark.setAttribute('data-entity', attribute);
        mark.appendChild(document.createTextNode(entity));
        document.querySelector(container).appendChild(mark);
      }

      else {
        document.querySelector(container).appendChild(document.createTextNode(entity));
      }

      offset = end;
    });

    document.querySelector(container).appendChild(document.createTextNode(text.slice(offset, text.length)));

    console.log(`%c💥  HTML markup\n%c<div class="entities">${document.querySelector(container).innerHTML}</div>`, 'font: bold 16px/2 arial, sans-serif', 'font: 13px/1.5 Consolas, "Andale Mono", Menlo, Monaco, Courier, monospace');

    if (typeof this.onRender === 'function') this.onRender();
  }
}

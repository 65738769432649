import React, { useEffect } from 'react'
import Page from '../../common/Page'
import {
  getTranscriptions,
  transcribe,
  deleteFile,
  deleteTranscription,
  transcriptionComplete
} from './../../../actions/transcriptionsActions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Table from './TranscriptionsTable'
import UploadFile from './UploadFile'
import { makeStyles } from '@material-ui/styles'
import io from 'socket.io-client'
import { SOCKET_URL } from '../../../config'

const useStyles = makeStyles(theme => ({
  page: {
    alignSelf: 'center'
  },
  table: {
    padding: theme.spacing(2),
    flexBasis: '100%'
  }
}))

const mapStateToProps = state => ({
  transcriptions: state.transcriptions && state.transcriptions.transcriptions,
  token: state.auth && state.auth.token
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTranscriptions,
      transcribe,
      deleteFile,
      deleteTranscription,
      transcriptionComplete
    },
    dispatch
  )

const List = ({ getTranscriptions, transcribe, deleteTranscription, deleteFile, transcriptions, token, transcriptionComplete }) => {
  const classes = useStyles()
  // the second argument [] is to only run useEffect on first render.
  useEffect(() => {
    getTranscriptions()
    const socket = io.connect(SOCKET_URL)
    socket
      .on('connect', () => {
        console.log('client connected')
        socket.emit('authenticate', { token: token }) // send the jwt
      })
      .on('transcriptionDone', notification => {
        transcriptionComplete(notification)
      })
  }, [])

  return (
    <Page className={classes.page}>
      <Table
        className={classes.table}
        transcriptions={transcriptions}
        transcribe={transcribe}
        deleteFile={deleteFile}
        deleteTranscription={deleteTranscription}
      />
      <UploadFile />
    </Page>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)

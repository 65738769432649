import React from 'react'
import LoadingOval from '../LoadingOval'
import Page from './Page'

export default () => {
  return (
    <Page>
      <LoadingOval />
    </Page>
  )
}

import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import videos from './videosReducer'
import related from './relatedReducer'
import search from './searchReducer'
import socket from './socketReducer'
import auth from './authReducer'
import help from './helpReducer'
import menu from './menuReducer'
import feed from './feedReducer'
import createFeed from './createFeedReducer'
import notifications from './notificationsReducer'
import onBoarding from './onBoardingReducer'
import sources from './sourcesReducer'
import transcriptions from './transcriptionsReducer'
import reader from './reader'
import notepad from './notepadReducer'

const appReducer = combineReducers({
  feed,
  auth,
  help,
  form,
  socket,
  videos,
  related,
  search,
  menu,
  createFeed,
  notifications,
  onBoarding,
  sources,
  transcriptions,
  reader,
  notepad
})

export default appReducer

import React, { useEffect } from 'react'
import Page from '../../common/Page'
import {
  getTranscriptions,
  transcribe,
  deleteFile,
  deleteTranscription
} from './../../../actions/transcriptionsActions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  transcriptions: state.transcriptions && state.transcriptions.transcriptions
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTranscriptions,
      transcribe,
      deleteFile,
      deleteTranscription
    },
    dispatch
  )

const List = ({ getTranscriptions, transcribe, deleteTranscription, deleteFile, transcriptions }) => {
  useEffect(() => {
    getTranscriptions()
  }, [])

  return (
    <Page />
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)

import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    Avatar,
    Paper,
    Button,
    FormControl,
    InputLabel,
    Input,
    Typography,
    SnackbarContent
} from '@material-ui/core'
import { LockOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import Page from '../common/Page'
import { recoverPassword } from '../../actions/authActions'

const useStyles = makeStyles(theme => ({
    main: {
        width: 'auto',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        marginTop: theme.spacing(1)
    },
    submit: {
        marginTop: theme.spacing(3)
    },
    snackbar: {
        marginTop: theme.spacing(2)
    }
}))

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
          recoverPassword
        },
        dispatch
    )

const Recover = ({ recoverPassword }) => {
    const classes = useStyles()
    const [email, setEmail] = useState('')
    const [recoverSuccess, setRecoverSuccess] = useState(false)
    return (
        <Page noTopBar>
            <main className={classes.main}>
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlined />
                    </Avatar>
                    <Typography component='h1' variant='h5'>
                        Recover Password
                    </Typography>
                    <form
                        className={classes.form}
                        onSubmit={(event) => {
                          recoverPassword(email)
                          setRecoverSuccess('Go check your email inbox 🎉')
                          event.preventDefault()
                        }}
                    >
                        <FormControl margin='normal' required fullWidth>
                            <InputLabel htmlFor='email'>Email</InputLabel>
                            <Input
                                name='email'
                                type='email'
                                id='email'
                                onChange={(event) => {
                                    setEmail(event.target.value)
                                }}
                            />
                        </FormControl>
                        <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                        >
                            Recover Password
                        </Button>
                        {recoverSuccess && (
                            <SnackbarContent
                                className={classes.snackbar}
                                message={recoverSuccess}
                            />
                        )}
                    </form>
                </Paper>
            </main >
        </Page>
    )
}

export default connect(
    null,
    mapDispatchToProps
)(Recover)
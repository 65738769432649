import * as types from '../actions/types'

const initialReducerState = {
  result: ''
}

const notepadReducer = function (state = initialReducerState, action) {
  switch (action.type) {
    case types.NOTEPAD_SUCCESS:
      return Object.assign({}, state, {
        result: action.response
      })

    default: return state
  }
}

export default notepadReducer

import * as types from '../actions/types'

const initialVideoState = {
  showSuccess: false
}

const videosReducer = function (state = initialVideoState, action) {
  switch (action.type) {
    case types.NEW_VIDEO_SUCCESS:
      return Object.assign({}, state, {
        showSuccess: true
      })
    default: return state
  }
}
export default videosReducer

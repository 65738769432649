import {
  DELETE_TRANSCRIPTION,
  FETCH_TRANSCRIPTION_SUCCESS,
  FETCH_TRANSCRIPTIONS_SUCCESS,
  TRANSCRIPTION_COMPLETE,
  UPDATE_TRANSCRIPTION_START,
  UPDATE_TRANSCRIPTION_SUCCESS,
  UPDATE_TRANSCRIPTION_ERROR
} from '../actions/types'

const initialTranscriptionsState = {
  transcriptions: [],
  transcription: null,
  loading: false,
  updateTrancriptionError: null
}

const transcriptionsReducer = function (state = initialTranscriptionsState, action) {
  switch (action.type) {
    case UPDATE_TRANSCRIPTION_START:
      return Object.assign({}, state, {
        loading: true
      })
    case UPDATE_TRANSCRIPTION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        updateTrancriptionError: null
      })
    case UPDATE_TRANSCRIPTION_ERROR:
      return Object.assign({}, state, {
        loading: false,
        updateTrancriptionError: 'Error saving your changes'
      })
    case DELETE_TRANSCRIPTION:
      const nextState = Object.assign({}, state)
      if (state.transcription && state.transcription.id === action.transcriptionId) {
        nextState.transcription = null
      }
      nextState.transcriptions = nextState.transcriptions.filter(({ id }) =>
        id !== action.transcriptionId
      )
      return nextState
    case FETCH_TRANSCRIPTION_SUCCESS:
      return Object.assign({}, state, {
        transcription: action.transcription
      })
    case FETCH_TRANSCRIPTIONS_SUCCESS:
      return Object.assign({}, state, {
        transcriptions: action.transcriptions,
        transcription: null
      })
    case TRANSCRIPTION_COMPLETE:
      return Object.assign({}, state, {
        transcriptions: state.transcriptions.map((transcription) => {
          if (transcription.transcription_id === action.transcriptionId) {
            transcription.done = true
          }
          return transcription
        })
      })
    default:
      return state
  }
}

export default transcriptionsReducer

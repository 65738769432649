import React from 'react'
import transcriptionImg from './assets/images/transcription.png'
import notepadImg from './assets/images/notepad.png'
import { Link } from 'react-router-dom'
import { Paper, Grid, Card, CardMedia, CardActionArea, CardContent, Typography, CardActions, Button } from '@material-ui/core'
import { Redirect } from 'react-router'
import styles from './index.module.scss'
import Page from '../common/Page'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import Skeleton from '@material-ui/lab/Skeleton';

const mapStateToProps = state => ({
  loggedIn: state.auth && state.auth.loggedIn
})

const useStyles = makeStyles(theme => ({
  cardContent: {
    minHeight: '100px'
  },
  cardLink: {
    textDecoration: 'none',
    color: theme.palette.primary
  }
}))

const Portal = ({ loggedIn }) => {
  const classes = useStyles()
  if (!loggedIn) {
    return (
      <Redirect to='/' />
    )
  } else {
    return (
      <Page>
        <Paper className={styles.portal}>
          <Grid container justify='space-evenly' alignItems='center'>
            <Grid item className={styles.entry} xs={4}>
              <PortalCard
                title='Transcriptions'
                description='Manage your Audio/Video Transcriptions'
                imgSrc={transcriptionImg}
                linkTo='/transcriptions'
                classes={classes}
              />
            </Grid>
            <Grid item className={styles.entry} xs={4}>
              <PortalCard
                title='Notepad'
                description='Translate, Summarize, and Find Entities in any text'
                imgSrc={notepadImg}
                linkTo='/notepad'
                classes={classes}
              />
            </Grid>
            <Grid item className={styles.entry} xs={4}>
              <PortalCard
                skeleton
              >
                Coming soon...
              </PortalCard>
            </Grid>
          </Grid>
        </Paper>
      </Page>
    )
  }
}

const PortalCard = ({ title, description, imgSrc, linkTo, skeleton, classes, children }) => {
  if (skeleton) {
    return (
      <Card>
        <CardActionArea>
          <Skeleton variant="rect" height={140} />
          <CardContent>
            <Typography color='textSecondary' gutterBottom variant="h5" component="h2">
              {children}
            </Typography>
            <Skeleton height={3} />
            <Skeleton height={3} width="80%" />
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button disabled variant='contained' size="small" color="secondary">
            Open
          </Button>
          <Button disabled variant='contained' size="small" color="primary">
            Learn More
          </Button>
        </CardActions>
      </Card>
    )
  } else {
    return (
      <Card>
        <CardActionArea>
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height="140"
            image={imgSrc}
            title="Contemplative Reptile"
          />
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button variant='contained' size="small" color="secondary">
            <Link
              style={{ textDecoration: 'none', color: '#ffffff' }}
              to={linkTo}>Open</Link>
          </Button>
          <Button disabled variant='contained' size="small" color="primary">
            Learn More
          </Button>
        </CardActions>
      </Card>
    )
  }
}

export default connect(
  mapStateToProps
)(Portal)

import React from "react";
import { toast } from "react-toastify";

export const successToast = message => {
  toast.success(message, {
    position: toast.POSITION.BOTTOM_RIGHT
  });
};

export const errorToast = message => {
  toast.error(message, {
    position: toast.POSITION.BOTTOM_RIGHT
  });
};

export const undoToast = (message, undoFunction, closeFunction) => {
  return toast.info(
    ({ closeToast }) => (
      <div className="toast-content">
        <span className="toast-content-text">{message}</span>
        <span
          className="toast-content-undo"
          onClick={() => {
            undoFunction();
            closeToast();
          }}
        >
          UNDO
        </span>
      </div>
    ),
    {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "dark-toast",
      closeOnClick: false,
      closeButton: false,
      onClose: closeFunction
    }
  );
};

import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import User from './User'
import { Link, withRouter } from 'react-router-dom'
import { Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import history from '../../../history'
import SvgIcon from '../SvgIcon'

const useStyles = makeStyles(theme => ({
  appBar: {
    display: 'flex',
    flexWrap: 'nowrap',
    boxSizing: 'border-box',
    zIndex: theme.zIndex.drawer + 1
  },
  logo: {
    height: 50,
    width: 50,
    display: 'inline-flex',
    marginRight: theme.spacing(2)
  },
  sessionTools: {
    textAlign: 'right'
  },
  grid: {
    display: 'flex'
  },
  nav: {
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 1
  },
  navLink: {
    marginRight: theme.spacing(2)
  },
  navText: {
    flexWrap: 'nowrap'
  }
}))

const TopBar = ({ match }) => {
  const classes = useStyles()
  return (
    <AppBar color='inherit' className={classes.appBar} position='fixed'>
      <Grid container alignItems='center'>
        <Grid item xs={9} className={classes.grid}>
          <Link to='/portal'>
            <SvgIcon className={classes.logo} icon='logo_primary' />
          </Link>
          <nav className={classes.nav}>
            {/* <ButtonLink to='/reader'>
              Reader
            </ButtonLink> */}
            <ButtonLink to='/transcriptions'>
              Transcriptions
            </ButtonLink>
            {/* <ButtonLink to='/generate'>
              Generate Content
          </ButtonLink> */}
            <ButtonLink to='/notepad'>
              Notepad
            </ButtonLink>
          </nav>
        </Grid>
        <Grid item xs={3} className={classes.sessionTools}>
          <User />
        </Grid>
      </Grid>
    </AppBar>
  )
}

const ButtonLink = withRouter(({ children, to, match }) => {
  const isActive = match.path.startsWith(to)
  const classes = useStyles()
  return (
    <Button
      className={classes.navLink}
      variant={isActive ? 'contained' : 'text'}
      color={isActive ? 'primary' : 'default'}
      onClick={() => history.push(to)}
    >
      {children}
    </Button>
  )
})

export default withRouter(TopBar)

import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Avatar, Paper, Button, FormControl, InputLabel, Input, Typography } from '@material-ui/core'
import { LockOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import Page from '../common/Page'
import { createAccount } from '../../actions/authActions'

const useStyles = makeStyles(theme => ({
  main: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(3)
  }
}))

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createAccount
    },
    dispatch
  )

const SignUp = ({ match, createAccount }) => {
  const confirmationId = match.params.confirmationId
  console.log(confirmationId)
  const classes = useStyles()
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [jobTitle, setJobTitle] = useState('')
  const [passwordError, setPasswordError] = useState(false)

  return (
    <Page noTopBar>
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlined />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Create your Account
          </Typography>
          <form
            className={classes.form}
            onSubmit={(event) => {
              if (password === passwordConfirmation && confirmationId) {
                createAccount({
                  password,
                  firstName,
                  lastName,
                  phoneNumber,
                  jobTitle,
                  confirmationId
                })
              } else {
                setPasswordError(true)
              }
              event.preventDefault()
            }}
          >
            <FormControl margin='normal' required fullWidth>
              <InputLabel htmlFor='firstName'>First Name</InputLabel>
              <Input
                name='firstName'
                type='text'
                id='firstName'
                onChange={(event) => {
                  setFirstName(event.target.value)
                }}
              />
            </FormControl>
            <FormControl margin='normal' required fullWidth>
              <InputLabel htmlFor='lastName'>Last Name</InputLabel>
              <Input
                name='lastName'
                type='text'
                id='lastName'
                onChange={(event) => {
                  setLastName(event.target.value)
                }}
              />
            </FormControl>
            <FormControl margin='normal' required fullWidth>
              <InputLabel htmlFor='phoneNumber'>Phone Number</InputLabel>
              <Input
                name='phoneNumber'
                type='text'
                id='phoneNumber'
                onChange={(event) => {
                  setPhoneNumber(event.target.value)
                }}
              />
            </FormControl>
            <FormControl margin='normal' required fullWidth>
              <InputLabel htmlFor='jobTitle'>Job Title</InputLabel>
              <Input
                name='jobTitle'
                type='text'
                id='jobTitle'
                onChange={(event) => {
                  setJobTitle(event.target.value)
                }}
              />
            </FormControl>
            <FormControl margin='normal' required fullWidth>
              <InputLabel htmlFor='password'>Your new password</InputLabel>
              <Input
                name='password'
                type='password'
                id='password'
                onChange={(event) => {
                  setPassword(event.target.value)
                }}
              />
            </FormControl>
            <FormControl margin='normal' required fullWidth>
              <InputLabel htmlFor='confirm-password'>Confirm your new password</InputLabel>
              <Input
                name='confirm-password'
                type='password'
                id='confirm-password'
                onChange={(event) => {
                  setPasswordConfirmation(event.target.value)
                }}
              />
            </FormControl>
            {passwordError && <p>Your password do not match</p>}
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
            >
              Create your account!
            </Button>
          </form>
        </Paper>
      </main >
    </Page>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp)

import * as types from '../actions/types'

const initialAuthState = {
  loggedIn: false,
  registerFailed: false,
  loginFailed: false,
  verifySuccessfull: false,
  errorMessage: '',
  token: '',
  feeds: [],
  firstName: null,
  lastName: null,
  email: null,
  text_size: 'normal'
}

const authReducer = function (state = initialAuthState, action) {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        token: action.token,
        loginFailed: false,
        verifySuccessfull: false,
        feeds: action.feeds,
        firstName: action.firstName,
        lastName: action.lastName,
        email: action.email,
        loggedIn: true
      })
    case types.LOGIN_FAILURE:
      return Object.assign({}, state, {
        errorMessage: action.errorMessage,
        loginFailed: true,
        token: ''
      })

    case types.VERIFY_SUCCESS:
      return Object.assign({}, state, {
        verifySuccessfull: true
      })

    case types.RECOVERED_PASSWORD:
      return Object.assign({}, state, {
        recoveredPassword: true
      })

    case types.RESETTED_PASSWORD:
      return Object.assign({}, state, {
        resettedPassword: true
      })

    case types.SIGNUP_SUCCESS:
      return Object.assign({}, state, {
        token: action.token,
        loggedIn: true
      })
    case types.SIGNUP_FAILURE:
      return Object.assign({}, state, {
        errorMessage: action.errorMessage,
        registerFailed: true
      })
    case types.SIGNUP_FAILURE_HIDE_ERROR:
      return Object.assign({}, state, {
        registerFailed: false
      })

    case types.LOGOUT:
      return Object.assign({}, state, {
        token: '',
        loggedIn: false
      })

    case types.SAVE_USER_INFO:
      return Object.assign({}, state, {
        text_size: action.info.text_size
      })

    default: return state
  }
}

export default authReducer

import {
  FETCH_UNREAD_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_ERROR,
  FETCH_ALL_NOTIFICATIONS,
  FETCH_ALL_NOTIFICATIONS_SUCCESS,
  FETCH_ALL_NOTIFICATIONS_ERROR,
  RECEIVE_NOTIFICATION,
  NUMBER_OF_UNREAD_NOTIFICATIONS,
  MARK_AS_READ
} from '../actions/types'

const initialNotificationsState = {
  fetchingNotifications: false,
  unreadNotifications: [],
  fetchingAllNotifications: false,
  allNotifications: [],
  unreadNotificatonsNumber: 0
}

const notificationsReducer = function (
  state = initialNotificationsState,
  action
) {
  switch (action.type) {
    case FETCH_UNREAD_NOTIFICATIONS:
      return Object.assign({}, state, {
        fetchingNotifications: true
      })

    case FETCH_NOTIFICATIONS_SUCCESS:
      return Object.assign({}, state, {
        fetchingNotifications: false,
        unreadNotifications: action.notifications
      })

    case FETCH_NOTIFICATIONS_ERROR:
      return Object.assign({}, state, {
        fetchingNotifications: false,
        unreadNotifications: []
      })

    case FETCH_ALL_NOTIFICATIONS:
      return Object.assign({}, state, {
        fetchingAllNotifications: true
      })

    case FETCH_ALL_NOTIFICATIONS_SUCCESS:
      return Object.assign({}, state, {
        fetchingAllNotifications: false,
        allNotifications: action.notifications
      })

    case FETCH_ALL_NOTIFICATIONS_ERROR:
      return Object.assign({}, state, {
        fetchingAllNotifications: false,
        allNotifications: []
      })

    case MARK_AS_READ:
      return Object.assign({}, state, {
        numberOfUnreadNotifications: state.numberOfUnreadNotifications - action.notificationIds.length
      })

    case RECEIVE_NOTIFICATION:
      return Object.assign({}, state, {
        numberOfUnreadNotifications: state.numberOfUnreadNotifications + 1
      })

    case NUMBER_OF_UNREAD_NOTIFICATIONS:
      return Object.assign({}, state, {
        numberOfUnreadNotifications: action.numberOfUnreadNotifications
      })

    default:
      return state
  }
}

export default notificationsReducer

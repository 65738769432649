import {
  READER_FETCH_FEEDS_START,
  READER_FETCH_FEEDS_SUCCESS,
  READER_FETCH_FEEDS_ERROR
} from '../actions/reader'

const initialState = {
  feeds: [],
  isLoadingFeeds: false
}

const reducer = function (state = initialState, action) {
  switch (action.type) {
    case READER_FETCH_FEEDS_START:
      return Object.assign({}, state, {
        isLoadingFeeds: true
      })

    case READER_FETCH_FEEDS_SUCCESS:
      return Object.assign({}, state, {
        isLoadingFeeds: false,
        feeds: action.feeds
      })

    case READER_FETCH_FEEDS_ERROR:
      return Object.assign({}, state, {
        isLoadingFeeds: false,
        feeds: []
      })

    default:
      return state
  }
}

export default reducer

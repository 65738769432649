import * as types from '../actions/types'

const initialSocketState = {
  message: ''
}

const socketReducer = function (state = initialSocketState, action) {
  switch (action.type) {
    case types.MESSAGE:
      return Object.assign({}, state, {
        message: action.data
      })
    default:
      return state
  }
}

export default socketReducer

import React, { useState } from 'react'
import { Snackbar as SnackbarMUI, SnackbarContent, IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing
  },
  success: {
    backgroundColor: theme.palette.green.main,
    color: theme.palette.green.contrastText
  },
  warning: {
    backgroundColor: theme.palette.orange.main,
    color: theme.palette.orange.contrastText
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  info: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const Snackbar = ({ type, message }) => {
  const classes = useStyles()
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(true)

  let Icon
  switch (type) {
    case 'success':
      Icon = CheckCircleIcon
      break
    case 'warning':
      Icon = WarningIcon
      break
    case 'error':
      Icon = ErrorIcon
      break
    case 'info':
      Icon = InfoIcon
      break
    default:
      Icon = InfoIcon
  }

  return (
    <SnackbarMUI
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={isSnackbarOpen}
      onClose={() => setIsSnackbarOpen(false)}
      autoHideDuration={6000}
    >
      <SnackbarContent
        className={classes[type]}
        message={(
          <span className={classes.message}>
            <Icon className={classes.icon} />
            <Typography className={classes[type]}>{message}</Typography>
          </span>
        )}
        action={[
          <IconButton
            color='inherit'
            onClick={() => setIsSnackbarOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </SnackbarMUI>
  )
}

export default Snackbar

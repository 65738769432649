import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router'
import Feed from './Feed'
import Sidebar from './Sidebar'

const mapStateToProps = state => ({
  loggedIn: state.auth && state.auth.loggedIn
})

const ReaderRouter = ({ loggedIn }) => {
  if (!loggedIn) {
    return (
      <Redirect to='/' />
    )
  } else {
    return (
      <React.Fragment>
        <Sidebar />
        <Route exact path='/reader/:feedId' component={Feed} />
        {/* <Route exact path='/reader/:feedId/settings' component={FeedSettings} /> */}
      </React.Fragment>
    )
  }
}

export default connect(
  mapStateToProps
)(ReaderRouter)

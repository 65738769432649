import React from 'react'
import ReactFiles from 'react-files'
import { makeStyles } from '@material-ui/styles'
import { TextField, Button, FormControl } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(2)
  },
  file: {
    display: 'flex'
  }
}))

const Files = ({
  defaultValue = 'Please browse a file',
  callToAction = 'Browse file',
  extensions,
  setFile,
  filename,
  setFilename,
  setFilesError
}) => {
  const classes = useStyles()

  return (
    <FormControl className={classes.formControl} required>
      <ReactFiles
        className={classes.file}
        maxFiles={2}
        accepts={extensions}
        onChange={files => {
          if (files.length > 1) {
            files.splice(0, 1)
          }

          setFile(files[0])
          setFilename(files[0].name)
        }}
        onError={(error, file) => setFilesError(error.message)}
        clickable
      >
        <TextField disabled label='Filename' value={filename || defaultValue} />
        <Button className={classes.callToAction} variant='contained'>{callToAction}</Button>
      </ReactFiles>
    </FormControl>
  )
}

export default Files

import {
  DELETE_TRANSCRIPTION,
  FETCH_TRANSCRIPTION_SUCCESS,
  FETCH_TRANSCRIPTIONS_SUCCESS,
  UPLOAD_FILE_START,
  UPLOAD_FILE_ERROR,
  UPLOAD_PROGRESS,
  TRANSCRIPTION_COMPLETE,
  UPDATE_TRANSCRIPTION_START,
  UPDATE_TRANSCRIPTION_SUCCESS,
  UPDATE_TRANSCRIPTION_ERROR
} from './types'
import { BASE_URL } from '../config'
import { get, uploadFile as _uploadFile, post, put } from '../services'
import { _delete } from './../services'
import { setIsModalOpen } from './menuActions'

export const getTranscription = transcriptionId => dispatch => {
  get(
    `${BASE_URL}/transcription/${transcriptionId}`,
    dispatch,
    transcription => {
      dispatch(loadTranscription(transcription))
    },
    error => {
      console.error(error)
    }
  )
}

export const getTranscriptions = () => dispatch => {
  get(
    `${BASE_URL}/file`,
    dispatch,
    transcriptions => {
      dispatch(loadTranscriptions(transcriptions))
    },
    error => {
      console.error(error)
    }
  )
}

export const updateTranscription = (transcriptionId, draftJs) => dispatch => {
  dispatch(updateTranscriptionStart())
  put(
    `${BASE_URL}/transcription/${transcriptionId}`,
    draftJs,
    dispatch,
    transcription => {
      dispatch(updateTranscriptionSuccess())
    },
    error => {
      dispatch(updateTranscriptionError())
      console.error(error)
    }
  )
}

export const uploadFile = (file, language) => dispatch => {
  if (!file) {
    dispatch(uploadFileError(new Error('You must choose a file to upload')))
  } else if (!language) {
    dispatch(uploadFileError(new Error('You must select a language')))
  } else {
    dispatch(uploadFileStart())
    _uploadFile(
      file,
      language,
      dispatch,
      ({ fileId }) => {
        dispatch(setIsModalOpen(false))
        getTranscriptions()(dispatch)
      },
      error => {
        dispatch(uploadFileError(error))
      },
      progress => {
        dispatch(fileUploadProgress(progress))
      },
      false
    )
  }
}

export const transcribe = fileId => dispatch => {
  post(
    `${BASE_URL}/transcription`,
    { fileId },
    dispatch,
    () => {
      getTranscriptions()(dispatch)
    },
    error => {
      console.error(error)
    }
  )
}

export const deleteFile = fileId => dispatch => {
  _delete(
    `${BASE_URL}/file/${fileId}`,
    null,
    dispatch,
    () => {
      getTranscriptions()(dispatch)
    },
    error => {
      console.error(error)
    }
  )
}

export const deleteTranscription = transcriptionId => dispatch => {
  _delete(
    `${BASE_URL}/transcription/${transcriptionId}`,
    null,
    dispatch,
    () => {
      dispatch(_deleteTranscription(transcriptionId))
      getTranscriptions()(dispatch)
    },
    error => {
      console.error(error)
    }
  )
}

export const transcriptionComplete = (notification) => {
  return {
    type: TRANSCRIPTION_COMPLETE,
    transcriptionId: notification.transcriptionId
  }
}

export const uploadFileStart = () => {
  return {
    type: UPLOAD_FILE_START
  }
}

export const uploadFileError = error => {
  return {
    type: UPLOAD_FILE_ERROR,
    error
  }
}

export const fileUploadProgress = progress => {
  return {
    type: UPLOAD_PROGRESS,
    progress
  }
}

export const loadTranscription = transcription => {
  return {
    type: FETCH_TRANSCRIPTION_SUCCESS,
    transcription
  }
}

export const loadTranscriptions = transcriptions => {
  return {
    type: FETCH_TRANSCRIPTIONS_SUCCESS,
    transcriptions
  }
}

export const updateTranscriptionStart = () => {
  return {
    type: UPDATE_TRANSCRIPTION_START
  }
}

export const updateTranscriptionSuccess = () => {
  return {
    type: UPDATE_TRANSCRIPTION_SUCCESS
  }
}

export const updateTranscriptionError = () => {
  return {
    type: UPDATE_TRANSCRIPTION_ERROR
  }
}

export const _deleteTranscription = transcriptionId => {
  return {
    type: DELETE_TRANSCRIPTION,
    transcriptionId
  }
}

import React from 'react'
import { Modal as ModalMUI } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    borderRadius: '5px'
  }
}))

const Modal = ({ isModalOpen, setIsModalOpen, className, children }) => {
  const classes = useStyles()

  return (
    <ModalMUI open={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <div className={classNames(classes.modal, className)}>{children}</div>
    </ModalMUI>
  )
}

export default Modal

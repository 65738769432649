import {
  ADD_SEARCH_ARTICLES,
  SEARCH,
  SEARCH_ERROR,
  SEARCH_MORE,
  SET_SEARCH_ARTICLES
} from '../actions/types'

const initialSearchState = {
  articles: [],
  isSearching: false,
  isSearchingMore: false,
  hasSearched: false,
  searchTerm: '',
  page: 1
}

const searchReducer = function (state = initialSearchState, action) {
  switch (action.type) {
    case ADD_SEARCH_ARTICLES:
      return Object.assign({}, state, {
        isSearchingMore: false,
        articles: [
          ...state.articles,
          ...action.articles
        ]
      })
    case SEARCH:
      return Object.assign({}, state, {
        isSearching: true,
        hasSearched: false,
        searchTerm: action.searchTerm
      })
    case SEARCH_ERROR:
      return Object.assign({}, state, {
        isSearching: false,
        isSearchingMore: false,
        hasSearched: false,
        error: action.error
      })
    case SEARCH_MORE:
      return Object.assign({}, state, {
        isSearchingMore: true,
        searchTerm: action.searchTerm,
        page: action.page
      })
    case SET_SEARCH_ARTICLES:
      return Object.assign({}, state, {
        articles: action.articles,
        isSearching: false,
        hasSearched: true,
        page: 1
      })
    default:
      return state
  }
}

export default searchReducer

import * as types from '../actions/types'

const initialHelpState = {
  requestHelpModal: false
}

const helpReducer = function (state = initialHelpState, action) {
  switch (action.type) {
    case types.REQUEST_HELP_OPEN_MODAL:
      return Object.assign({}, state, {
        requestHelpModal: true
      })
    case types.REQUEST_HELP_CLOSE_MODAL:
      return Object.assign({}, state, {
        requestHelpModal: false
      })
    default:
      return state
  }
}

export default helpReducer

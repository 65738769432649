import React from 'react'
import TopBar from './TopBar'
import { CssBaseline } from '@material-ui/core'
import theme from '../../theme'
import { makeStyles } from '@material-ui/styles'
import classnames from 'classnames'

const useStyles = makeStyles({
  wrapperNoTopBar: {
    display: 'flex',
    flexBasis: '100%',
    justifyContent: 'space-around'
  },
  wrapper: {
    display: 'flex',
    flexBasis: '100%',
    paddingRight: theme.spacing(11),
    paddingLeft: theme.spacing(11),
    paddingTop: theme.spacing(8),
    justifyContent: 'space-around'
  }
})

const Page = ({ children, noTopBar = false }) => {
  const classes = useStyles()
  if (!noTopBar) {
    return (
      <div className={classnames(classes.wrapper)}>
        <CssBaseline />
        <TopBar />
        {children}
      </div>
    )
  } else {
    return (
      <div className={classes.wrapperNoTopBar}>
        <CssBaseline />
        {children}
      </div>
    )
  }
}

export default Page

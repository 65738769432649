export const MESSAGE = 'MESSAGE'

/**
 * Auth Actions
 */
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT = 'LOGOUT'
export const SIGNUP_FAILURE_HIDE_ERROR = 'SIGNUP_FAILURE_HIDE_ERROR'
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS'
export const SAVE_USER_INFO = 'SAVE_USER_INFO'
export const RECOVERED_PASSWORD = 'RECOVERED_PASSWORD'
export const RESETTED_PASSWORD = 'RESETTED_PASSWORD'

/**
 * Help Actions
 */
export const REQUEST_HELP_OPEN_MODAL = 'REQUEST_HELP_OPEN_MODAL'
export const REQUEST_HELP_CLOSE_MODAL = 'REQUEST_HELP_CLOSE_MODAL'
export const REQUEST_HELP = 'REQUEST_HELP'

/**
 * Related Articles Actions
 */
export const FETCH_RELATED_SUCCESS = 'FETCH_RELATED_SUCCESS'

/**
 * Feed Actions
 */
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS'
export const FETCH_ARTICLES_ERROR = 'FETCH_ARTICLES_ERROR'
export const FETCHING_ARTICLES = 'FETCHING_ARTICLES'
export const NEW_ARTICLE = 'NEW_ARTICLE'
export const ADD_ARTICLES = 'ADD_ARTICLES'
export const FETCHING_MORE_ARTICLES = 'FETCHING_MORE_ARTICLES'

export const FETCHING_FEEDS = 'FETCHING_FEEDS'
export const FETCH_FEEDS_SUCCESS = 'FETCH_FEEDS_SUCCESS'
export const FETCH_FEEDS_ERROR = 'FETCH_FEEDS_ERROR'

export const SELECT_ARTICLE = 'SELECT_ARTICLE'
export const CLOSE_ARTICLE = 'CLOSE_ARTICLE'

export const FETCH_FEED_SUCCESS = 'FETCH_FEED_SUCCESS'
export const CLOSE_FEED = 'CLOSE_FEED'

export const ADD_SOURCE = 'ADD_SOURCE'
export const ADD_SOURCE_SUCCESS = 'ADD_SOURCE_SUCCESS'
export const ADD_SOURCE_ERROR = 'ADD_SOURCE_ERROR'
export const REMOVE_SOURCE = 'REMOVE_SOURCE'
export const REMOVE_SOURCE_SUCCESS = 'REMOVE_SOURCE_SUCCESS'
export const REMOVE_SOURCE_ERROR = 'REMOVE_SOURCE_ERROR'

export const SET_VIEW_TYPE = 'SET_VIEW_TYPE'

export const SHARE_FEED_OPEN_MODAL = 'SHARE_FEED_OPEN_MODAL'
export const SHARE_FEED_CLOSE_MODAL = 'SHARE_FEED_CLOSE_MODAL'
export const SHARE_ARTICLE_OPEN_MODAL = 'SHARE_ARTICLE_OPEN_MODAL'
export const SHARE_ARTICLE_CLOSE_MODAL = 'SHARE_ARTICLE_CLOSE_MODAL'

export const SHARING = 'SHARING'
export const DONE_SHARING = 'DONE_SHARING'
export const ADD_SHARE_USER = 'ADD_SHARE_USER'
export const REMOVE_SHARE_USER = 'REMOVE_SHARE_USER'

export const REMOVE_ARTICLE = 'REMOVE_ARTICLE'
export const ADD_ARTICLE = 'ADD_ARTICLE'

export const SOURCE_ARTICLES_OPEN_MODAL = 'SOURCE_ARTICLES_OPEN_MODAL'
export const SOURCE_ARTICLES_CLOSE_MODAL = 'SOURCE_ARTICLES_CLOSE_MODAL'
export const SOURCE_ARTICLES_RESET_MODAL = 'SOURCE_ARTICLES_RESET_MODAL'
export const FETCH_SOURCE_ARTICLES_MODAL_SUCCESS = 'FETCH_SOURCE_ARTICLES_MODAL_SUCCESS'
export const FETCH_SOURCE_ARTICLES_MODAL_ERROR = 'FETCH_SOURCE_ARTICLES_MODAL_ERROR'
export const FETCHING_SOURCE_ARTICLES_MODAL = 'FETCHING_SOURCE_ARTICLES_MODAL'

export const FETCH_SOURCE_ARTICLES_SUCCESS = 'FETCH_SOURCE_ARTICLES_SUCCESS'
export const FETCH_SOURCE_ARTICLES_ERROR = 'FETCH_SOURCE_ARTICLES_ERROR'
export const FETCHING_SOURCE_ARTICLES = 'FETCHING_SOURCE_ARTICLES'
export const FETCH_MORE_SOURCE_ARTICLES_SUCCESS = 'FETCH_MORE_SOURCE_ARTICLES_SUCCESS'
export const FETCHING_MORE_SOURCE_ARTICLES = 'FETCHING_MORE_SOURCE_ARTICLES'

export const SAVE_SETTINGS_SUCCESS = 'SAVE_SETTINGS_SUCCESS'

export const SEARCH_USERS = 'SEARCH_USERS'

export const TOGGLE_SAVE_ARTICLE = 'TOGGLE_SAVE_ARTICLE'

/**
 * Videos Actions
 */
export const NEW_VIDEO_SUCCESS = 'NEW_VIDEO_SUCCESS'

/**
 * Menu Actions
 */
export const SET_MENU_TITLE = 'SET_MENU_TITLE'
export const SET_PAGE = 'SET_PAGE'
export const SET_TAB = 'SET_TAB'
export const SET_MODAL_OPEN = 'SET_MODAL_OPEN'
export const UPLOAD_FILE_OPEN_MODAL = 'UPLOAD_FILE_OPEN_MODAL'
export const UPLOAD_FILE_CLOSE_MODAL = 'UPLOAD_FILE_CLOSE_MODAL'
export const UPLOAD_FILE_START = 'UPLOAD_FILE_START'
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR'
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS'

/**
 * Create Feed
 */
export const CREATE_FEED_OPEN_MODAL = 'CREATE_FEED_OPEN_MODAL'
export const CREATE_FEED_CLOSE_MODAL = 'CREATE_FEED_CLOSE_MODAL'
export const FETCH_CREATE_FEED = 'FETCH_CREATE_FEED'
export const FETCH_CREATE_FEED_SUCCESS = 'FETCH_CREATE_FEED_SUCCESS'
export const FETCH_CREATE_FEED_ERROR = 'FETCH_CREATE_FEED_ERROR'

/**
 * Notifications
 */
export const FETCH_UNREAD_NOTIFICATIONS = 'FETCH_UNREAD_NOTIFICATIONS'
export const FETCH_ALL_NOTIFICATIONS = 'FETCH_ALL_NOTIFICATIONS'
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS'
export const FETCH_NOTIFICATIONS_ERROR = 'FETCH_NOTIFICATIONS_ERROR'
export const FETCH_ALL_NOTIFICATIONS_SUCCESS = 'FETCH_ALL_NOTIFICATIONS_SUCCESS'
export const FETCH_ALL_NOTIFICATIONS_ERROR = 'FETCH_ALL_NOTIFICATIONS_ERROR'
export const RECEIVE_NOTIFICATION = 'RECEIVE_NOTIFICATION'
export const NUMBER_OF_UNREAD_NOTIFICATIONS = 'NUMBER_OF_UNREAD_NOTIFICATIONS'
export const MARK_AS_READ = 'MARK_AS_READ'

/**
 * OnBoarding
 */
export const MAIN_ONBOARDING_READY = 'MAIN_ONBOARDING_READY'

/**
 * Search
 */
export const FETCHING_SOURCES = 'FETCHING_SOURCES'
export const FETCH_SOURCES_SUCCESS = 'FETCH_SOURCES_SUCCESS'
export const FETCH_SOURCES_ERROR = 'FETCH_SOURCES_ERROR'
export const SEARCH = 'SEARCH'
export const SEARCH_ERROR = 'SEARCH_ERROR'
export const SEARCH_MORE = 'SEARCH_MORE'
export const SET_SEARCH_ARTICLES = 'SET_SEARCH_ARTICLES'
export const ADD_SEARCH_ARTICLES = 'ADD_SEARCH_ARTICLES'

/**
 * Transcriptions
 */
export const FETCH_TRANSCRIPTION_SUCCESS = 'FETCH_TRANSCRIPTION_SUCCESS'
export const FETCH_TRANSCRIPTIONS_SUCCESS = 'FETCH_TRANSCRIPTIONS_SUCCESS'
export const DELETE_TRANSCRIPTION = 'DELETE_TRANSCRIPTION'
export const TRANSCRIPTION_COMPLETE = 'TRANSCRIPTION_COMPLETE'
export const UPDATE_TRANSCRIPTION_START = 'UPDATE_TRANSCRIPTION_START'
export const UPDATE_TRANSCRIPTION_SUCCESS = 'UPDATE_TRANSCRIPTION_SUCCESS'
export const UPDATE_TRANSCRIPTION_ERROR = 'UPDATE_TRANSCRIPTION_ERROR'

/**
 * Sources
 */
export const REQUEST_SOURCES = 'REQUEST_SOURCES'
export const REQUEST_SOURCES_ERROR = 'REQUEST_SOURCES_ERROR'
export const SET_SOURCES = 'SET_SOURCES'

/**
 * Notepad
 */
export const NOTEPAD_SUCCESS = 'NOTEPAD_SUCCESS'

import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import Page from '../common/Page'
import {
  Paper,
  TextField,
  Grid,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'
import displaCyENT from '../common/displacy-ent'
import './Entities.css'
import {
  BASE_URL
} from '../../config'
import { translate, summarize } from '../../actions/notepadActions'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Transformation from './Transformation'

const displacy = new displaCyENT(`${BASE_URL}/notepad/findEntities`, {
  container: '#result',
  defaultModel: 'en'
})

const model = 'pt';
const ents = ['per', 'org', 'date', 'loc', 'norp', 'fac', 'gpe'];

const mapStateToProps = state => ({
  loggedIn: state.auth && state.auth.loggedIn,
  token: state.auth && state.auth.token,
  result: state.notepad && state.notepad.result
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      translate,
      summarize
    },
    dispatch
  )

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  slider: {
    paddingTop: theme.spacing(3.5)
  },
  run: {
    display: 'flex',
    justifyContent: 'center'
  },
  wrapper: {
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  grid: {
    padding: theme.spacing(1)
  },
  actions: {
    display: 'flex',
    justifyContent: 'center'
  },
  textField: {
    lineHeight: 1.2
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200
  }
}))

const findEntities = (input, token) => {
  displacy.parse(input, model, ents, token)
}

const Notepad = ({ loggedIn, token, result, summarize, translate, location }) => {
  let transcription = ''
  const fromTranscription = location && location.state && location.state.fromTranscription
  const classes = useStyles()
  if (fromTranscription) {
    for (const block of location.state.draftJs.blocks) {
      transcription += `${block.text}\n\n`
    }
  }
  const [input, setInput] = useState(transcription)

  if (!loggedIn) {
    return (
      <Redirect to={{
        pathname: '/',
        state: { from: '/notepad' }
      }} />
    )
  } else {
    return (
      <Page className={classes.page}>
        <Paper className={classes.wrapper}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.grid}>
              <Transformations
                input={input}
                token={token}
                translate={translate}
                summarize={summarize}
                findEntities={findEntities}
                classes={classes}
              />
              <Input setInput={setInput} transcription={transcription} classes={classes} />
              <Output result={result} classes={classes} />
            </Grid>
          </Grid>
        </Paper>
      </Page >
    )
  }
}

const Transformations = ({ input, token, translate, summarize, findEntities, classes}) => {
  return (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel2a-content'
        id='panel2a-header'
      >
        <Typography className={classes.heading}>Transformations</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Transformation
          input={input}
          token={token}
          translate={translate}
          summarize={summarize}
          findEntities={findEntities}
          classes={classes}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const Input = ({ setInput, transcription, classes }) => {
  return (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography className={classes.heading}>Input</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <TextField
          id='input'
          label='Your Input'
          variant='outlined'
          rows={10}
          multiline
          autoFocus
          fullWidth
          margin='dense'
          defaultValue={transcription}
          onChange={(event) => { setInput(event.target.value) }}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const Output = ({ result, classes }) => {
  return (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel3a-content'
        id='panel3a-header'
      >
        <Typography className={classes.heading}>Output</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <p
          id='result'
          className='entities'
        >
          {result}
        </p>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notepad)

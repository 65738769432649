import {
  REQUEST_SOURCES,
  REQUEST_SOURCES_ERROR,
  SET_SOURCES
} from '../actions/types'

const initialSourcesState = {
  sources: [],
  error: null,
  isRequestingSources: false
}

const sourcesReducer = function (state = initialSourcesState, action) {
  switch (action.type) {
    case REQUEST_SOURCES:
      return Object.assign({}, state, {
        isRequestingSources: true
      })
    case REQUEST_SOURCES_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        isRequestingSources: false
      })
    case SET_SOURCES:
      return Object.assign({}, state, {
        sources: action.sources,
        isRequestingSources: false
      })
    default:
      return state
  }
}

export default sourcesReducer

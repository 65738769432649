import React, { useState } from 'react'
import { Button, FormControl, Select, InputLabel, MenuItem, Fab, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Files from '../../../common/Files'
import { bindActionCreators } from 'redux'
import { uploadFile } from '../../../../actions/transcriptionsActions'
import { setIsModalOpen, uploadFileOpenModal } from '../../../../actions/menuActions'
import { connect } from 'react-redux'
import { Circle } from 'rc-progress'
import Snackbar from '../../../common/Snackbar'
import Modal from '../../../common/Modal'
import AddIcon from '@material-ui/icons/Add'

const mapStateToProps = state => ({
  uploadFileError: state.menu.uploadFileError,
  uploadProgress: state.menu.uploadProgress,
  isSubmitting: state.menu.uploadFileStart,
  isModalOpen: state.menu.isModalOpen
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      uploadFile,
      setIsModalOpen,
      uploadFileOpenModal
    },
    dispatch
  )

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'absolute',
    top: theme.spacing(8),
    left: theme.spacing(1)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  circle: {
    width: 400,
    height: 400
  },
  formControl: {
    display: 'flex !important',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(1)
  },
  file: {
    display: 'flex !important',
    alignItems: 'flex-end'
  },
  modal: {
    padding: theme.spacing(3)
  }
}))

const UploadFile = ({ uploadFile, isSubmitting, uploadFileError, uploadProgress, isModalOpen, setIsModalOpen, uploadFileOpenModal }) => {
  const classes = useStyles()
  const [language, setLanguage] = useState('pt')
  const [filename, setFilename] = useState('')
  const [file, setFile] = useState(null)
  const [filesError, setFilesError] = useState('')

  return (
    <React.Fragment>
      <Fab
        color='primary'
        variant='extended'
        aria-label='Upload'
        className={classes.fab}
        onClick={() => uploadFileOpenModal()}
      >
        <AddIcon className={classes.extendedIcon} />
        Upload
      </Fab>
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        className={isSubmitting ? classes.circle : classes.modal}
      >
        {isSubmitting ? (
          <Circle percent={uploadProgress} strokeWidth={4} strokeColor='#198cff' />
        ) : (
            <form>
              <Typography variant='h5'>Select your file</Typography>
              <Files
                // Trint supported formats
                extensions={['.mp3', '.mp4', '.m4a', '.aac', '.wav', '.wma', '.mov', '.avi']}
                // AWS supported formats
                //extensions={['.mp3', '.mp4', '.wav', '.flac']}
                setFile={setFile}
                filename={filename}
                setFilename={setFilename}
                setFilesError={setFilesError}
              />
              <FormControl className={classes.formControl} required>
                <InputLabel htmlFor='language'>Language</InputLabel>
                <Select
                  value={language}
                  onChange={({ target }) => setLanguage(target.value)}
                  inputProps={{
                    name: 'language',
                    id: 'language'
                  }}
                >
                  {/* AWS languages
                  <MenuItem value='pt-BR'>Portuguese</MenuItem>
                  <MenuItem value='en-GB'>English (British)</MenuItem>
                  <MenuItem value='en-US'>English (North American)</MenuItem>
                  <MenuItem value='en-AU'>English (Australian)</MenuItem>
                  <MenuItem value='es-US'>Spanish (American)</MenuItem>
                  <MenuItem value='de-DE'>German</MenuItem>
                  <MenuItem value='fr-FR'>French (Europe)</MenuItem>
                  <MenuItem value='fr-CA'>French (Canadian)</MenuItem>
                  <MenuItem value='it-IT'>Italian</MenuItem>
                  */}
                  {/* Trint languages */}
                  <MenuItem value='cs'>Czech</MenuItem>
                  <MenuItem value='da'>Danish</MenuItem>
                  <MenuItem value='nl'>Dutch</MenuItem>
                  <MenuItem value='pt'>Portuguese</MenuItem>
                  <MenuItem value='en'>English (All accents)</MenuItem>
                  <MenuItem value='en-GB'>English (British)</MenuItem>
                  <MenuItem value='fi'>Finnish</MenuItem>
                  <MenuItem value='fr'>French</MenuItem>
                  <MenuItem value='de'>German</MenuItem>
                  <MenuItem value='hu'>Hungarian</MenuItem>
                  <MenuItem value='it'>Italian</MenuItem>
                  <MenuItem value='pl'>Polish</MenuItem>
                  <MenuItem value='ro'>Romanian</MenuItem>
                  <MenuItem value='su'>Russian</MenuItem>
                  <MenuItem value='es'>Spanish (European)</MenuItem>
                  <MenuItem value='sv'>Swedish</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => uploadFile(file, language)}
                >
                  Upload
              </Button>
              </FormControl>
            </form>
          )}
      </Modal>
      {filesError && <Snackbar type='error' message={filesError} />}
      {uploadFileError && <Snackbar type='error' message={uploadFileError} />}
    </React.Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadFile)

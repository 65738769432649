import React from 'react'
import {SvgIcon as SvgIconMUI} from '@material-ui/core'

const SvgIcon = ({className, icon}) => {
  return (
    <SvgIconMUI className={className}>
      <use xlinkHref={require('../../assets/sprites/sprite.svg') + '#' + icon} />
    </SvgIconMUI>
  )
}

export default SvgIcon

import {
  BASE_URL
} from '../config'
import {
  get
} from '../services'
import {
  loadToken
} from '../localStorage'

export const READER_FETCH_FEEDS_START = 'READER_FETCH_FEEDS_START'
export const READER_FETCH_FEEDS_SUCCESS = 'READER_FETCH_FEEDS_SUCCESS'
export const READER_FETCH_FEEDS_ERROR = 'READER_FETCH_FEEDS_ERROR'

export const getFeeds = () => (dispatch) => {
  dispatch(fetchFeedsStart())
  get(BASE_URL + `/user/feed`, dispatch,
    (json) => {
      let feeds = json
      Promise.all(feeds.map((feed) => {
        let request = new Request(BASE_URL + `/feed/${feed.id}/source`)
        let reqHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + loadToken()
        })
        let config = {
          method: 'GET',
          headers: reqHeaders,
          cache: 'default'
        }
        return fetch(request, config)
          .then((response) => {
            return response.json().catch(_ => response)
          })
      })).then((values) => {
        values.forEach((sources, index) => {
          feeds[index].sources = sources.reduce((acc, e) => {
            acc[e.id] = e
            return acc
          }, {})
        })
        dispatch(fetchFeedsSuccess(feeds))
      }).catch((error) => {
        dispatch(fetchFeedsError(error))
      })
    },
    (error) => {
      dispatch(fetchFeedsError(error))
    }
  )
}
const fetchFeedsStart = () => {
  return {
    type: READER_FETCH_FEEDS_START
  }
}

const fetchFeedsSuccess = (feeds) => {
  return {
    type: READER_FETCH_FEEDS_SUCCESS,
    feeds
  }
}

const fetchFeedsError = () => {
  return {
    type: READER_FETCH_FEEDS_ERROR
  }
}

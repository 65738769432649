import {
    CREATE_FEED_OPEN_MODAL,
    CREATE_FEED_CLOSE_MODAL,
    FETCH_CREATE_FEED,
    FETCH_CREATE_FEED_SUCCESS,
    FETCH_CREATE_FEED_ERROR
} from '../actions/types';


const initialCreateFeedState = {
    modalOpen: false,
    fecthing: false,
    error: null
}

const createFeedReducer = function (state = initialCreateFeedState, action) {
    switch (action.type) {
        case CREATE_FEED_OPEN_MODAL:
            return Object.assign({}, state, {
                modalOpen: true
            });

        case CREATE_FEED_CLOSE_MODAL:
            return Object.assign({}, state, {
                modalOpen: false
            });

        case FETCH_CREATE_FEED:
            return Object.assign({}, state, {
                fecthing: true,
                error: null
            });

        case FETCH_CREATE_FEED_SUCCESS:
            return Object.assign({}, state, {
                fecthing: false,
                error: null,
                modalOpen: false
            });

        case FETCH_CREATE_FEED_ERROR:
            return Object.assign({}, state, {
                fecthing: false,
                error: action.error
            });

        default: return state;
    }
}

export default createFeedReducer;
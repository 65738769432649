import {
  FETCH_ARTICLES_SUCCESS,
  FETCH_ARTICLES_ERROR,
  FETCHING_ARTICLES,
  FETCHING_FEEDS,
  FETCH_FEEDS_SUCCESS,
  FETCH_FEEDS_ERROR,
  SELECT_ARTICLE,
  CLOSE_ARTICLE,
  FETCH_FEED_SUCCESS,
  CLOSE_FEED,
  ADD_SOURCE_SUCCESS,
  ADD_SOURCE_ERROR,
  ADD_SOURCE,
  REMOVE_SOURCE,
  REMOVE_SOURCE_SUCCESS,
  REMOVE_SOURCE_ERROR,
  ADD_ARTICLES,
  SET_VIEW_TYPE,
  FETCHING_MORE_ARTICLES,
  SHARE_FEED_OPEN_MODAL,
  SHARE_FEED_CLOSE_MODAL,
  ADD_SHARE_USER,
  REMOVE_SHARE_USER,
  SHARING,
  DONE_SHARING,
  SHARE_ARTICLE_OPEN_MODAL,
  SHARE_ARTICLE_CLOSE_MODAL,
  REMOVE_ARTICLE,
  ADD_ARTICLE,
  FETCHING_SOURCES,
  FETCH_SOURCES_SUCCESS,
  FETCH_SOURCES_ERROR,
  SOURCE_ARTICLES_OPEN_MODAL,
  SOURCE_ARTICLES_CLOSE_MODAL,
  SOURCE_ARTICLES_RESET_MODAL,
  FETCHING_SOURCE_ARTICLES_MODAL,
  FETCH_SOURCE_ARTICLES_MODAL_SUCCESS,
  FETCH_SOURCE_ARTICLES_MODAL_ERROR,
  FETCHING_SOURCE_ARTICLES,
  FETCH_SOURCE_ARTICLES_SUCCESS,
  FETCH_SOURCE_ARTICLES_ERROR,
  FETCHING_MORE_SOURCE_ARTICLES,
  FETCH_MORE_SOURCE_ARTICLES_SUCCESS,
  SAVE_SETTINGS_SUCCESS,
  SEARCH_USERS,
  TOGGLE_SAVE_ARTICLE
} from '../actions/types'
import {
  TITLE_ONLY_VIEW,
  SOURCE_VIEW
} from '../consts'

const initialFeedState = {
  articles: [],
  articlesLastPage: false,
  id: null,
  fetching: false,
  feeds: [],
  fetchingFeeds: false,
  fetchingMoreArticles: false,
  fetchingMoreSourceArticles: false,
  fetchingSources: false,
  fetchingSourceArticles: false,
  fetchingSourceArticlesModal: false,
  refreshArticles: false,
  searchSources: null,
  selectedArticle: null,
  selectedFeed: null,
  selectedShareFeed: null,
  selectedSource: null,
  selectedSourceArticles: [],
  selectedSourceModal: null,
  selectedSourceArticlesModal: [],
  sharing: false,
  shareFeedModalOpen: false,
  shareTitle: null,
  shareType: 'feed',
  shareUsers: [],
  sourceArticlesModalOpen: false,
  sourceError: null,
  users: [],
  sources: {},
  viewType: window.localStorage.getItem('viewType') || TITLE_ONLY_VIEW
}

const feedReducer = function (state = initialFeedState, action) {
  switch (action.type) {
    case FETCH_ARTICLES_SUCCESS:
      return Object.assign({}, state, {
        articles: action.articles,
        id: action.feedId,
        fetching: false,
        articlesLastPage: action.articles.length % 20 !== 0,
        articlesPage: 1
      })

    case ADD_ARTICLES:
      return Object.assign({}, state, {
        articles: [
          ...state.articles,
          ...action.articles
        ],
        articlesLastPage: action.articles.length === 0,
        fetchingMoreArticles: false,
        articlesPage: action.page
      })

    case FETCHING_MORE_ARTICLES:
      return Object.assign({}, state, {
        fetchingMoreArticles: true,
        refreshArticles: false,
        id: action.feed_id
      })

    case FETCHING_ARTICLES:
      return Object.assign({}, state, {
        fetching: true,
        refreshArticles: false,
        id: action.feed_id
      })

    case FETCH_ARTICLES_ERROR:
      return Object.assign({}, state, {
        articles: [],
        id: action.feed_id,
        fetching: false,
        fetchingMoreArticles: false
      })

    case FETCHING_FEEDS:
      return Object.assign({}, state, {
        fetchingFeeds: true
      })

    case FETCH_FEEDS_SUCCESS:
      const sources = Object.assign({}, state.sources)
      action.feeds.forEach(({sources: feedSources}) => {
        Object.assign(sources, feedSources)
      })
      return Object.assign({}, state, {
        fetchingFeeds: false,
        feeds: action.feeds,
        sources
      })

    case FETCH_FEEDS_ERROR:
      return Object.assign({}, state, {
        fetchingFeeds: false,
        feeds: []
      })

    case SELECT_ARTICLE:
      return Object.assign({}, state, {
        selectedArticle: action.article
      })

    case CLOSE_ARTICLE:
      return Object.assign({}, state, {
        selectedArticle: null
      })

    case FETCH_FEED_SUCCESS:
      const selectedFeedWithSources = Object.assign({}, action.feed)
      selectedFeedWithSources.sources = Object.values(action.feed.sources)
      return Object.assign({}, state, {
        selectedFeed: selectedFeedWithSources,
        id: selectedFeedWithSources.id,
        refreshArticles: true
      })

    case CLOSE_FEED:
      return Object.assign({}, state, {
        selectedFeed: null,
        id: null,
        articles: [],
        selectedSource: null,
        selectedSourceArticles: [],
        viewType: state.viewType === SOURCE_VIEW ? TITLE_ONLY_VIEW : state.viewType
      })

    case ADD_SOURCE_SUCCESS:
      return Object.assign({}, state, {
        refreshArticles: true
      })

    case ADD_SOURCE_ERROR:
      const addSourceError = state.selectedFeed
      addSourceError.sources = addSourceError.sources.filter(element => element.id !== action.source_id)
      return Object.assign({}, state, {
        selectedFeed: addSourceError,
        sourceError: 'An error occurred adding a new source!'
      })

    case ADD_SOURCE:
      let addSourceSelected
      const addSource = state.feeds.map(feed => {
        if (feed.id === action.feedId) {
          feed.sources[action.source.id] = action.source
          addSourceSelected = Object.assign({}, feed)
        }
        return feed
      })
      return Object.assign({}, state, {
        feeds: addSource,
        selectedFeed: addSourceSelected,
        sourceError: null
      })

    case REMOVE_SOURCE_SUCCESS:
      return Object.assign({}, state, {
        refreshArticles: true
      })

    case REMOVE_SOURCE_ERROR:
      const removeSourceError = state.selectedFeed
      removeSourceError.sources = [...removeSourceError.sources, action.source]
      return Object.assign({}, state, {
        selectedFeed: removeSourceError,
        sourceError: 'An error occurred removing a source!'

      })

    case REMOVE_SOURCE:
      let removeSourceSelected
      const removeSource = state.feeds.map(feed => {
        if (feed.id === action.feedId) {
          delete feed.sources[action.sourceId]
          removeSourceSelected = feed
        }
        return feed
      })
      return Object.assign({}, state, {
        feeds: removeSource,
        selectedFeed: removeSourceSelected,
        sourceError: null
      })

    case SET_VIEW_TYPE:
      return Object.assign({}, state, {
        viewType: action.viewType
      })

    case SHARE_FEED_OPEN_MODAL:
      return Object.assign({}, state, {
        shareFeedModalOpen: true,
        selectedShareFeed: action.selectedFeed,
        shareTitle: action.shareTitle,
        shareType: action.shareType
      })

    case SHARE_FEED_CLOSE_MODAL:
      return Object.assign({}, state, {
        shareFeedModalOpen: false,
        shareUsers: [],
        selectedShareFeed: null,
        shareTitle: null,
        shareType: 'feed'
      })

    case SHARE_ARTICLE_OPEN_MODAL:
      return Object.assign({}, state, {
        shareFeedModalOpen: true,
        selectedShareArticle: action.selectedArticle,
        shareTitle: action.shareTitle,
        shareType: action.shareType
      })

    case SHARE_ARTICLE_CLOSE_MODAL:
      return Object.assign({}, state, {
        shareFeedModalOpen: false,
        shareUsers: [],
        selectedShareArticle: null,
        shareTitle: null,
        shareType: 'feed'
      })

    case ADD_SHARE_USER:
      return Object.assign({}, state, {
        userToShare: action.user
      })

    case REMOVE_SHARE_USER:
      return Object.assign({}, state, {
        userToShare: void 0
      })

    case SHARING:
      return Object.assign({}, state, {
        sharing: true
      })

    case DONE_SHARING:
      return Object.assign({}, state, {
        sharing: false
      })

    case REMOVE_ARTICLE:
      return Object.assign({}, state, {
        articles: state.articles.filter(article => article.id !== action.article_id)
      })

    case ADD_ARTICLE:
      return Object.assign({}, state, {
        articles: [action.article, ...state.articles]
      })

    case FETCHING_SOURCES:
      return Object.assign({}, state, {
        fetchingSources: true
      })

    case FETCH_SOURCES_SUCCESS:
      return Object.assign({}, state, {
        fetchingSources: false,
        searchSources: action.sources
      })

    case FETCH_SOURCES_ERROR:
      return Object.assign({}, state, {
        fetchingSources: false,
        searchSources: null
      })

    case SOURCE_ARTICLES_OPEN_MODAL:
      return Object.assign({}, state, {
        sourceArticlesModalOpen: true,
        selectedSourceModal: state.sources[action.source] ? state.sources[action.source] : null
      })

    case SOURCE_ARTICLES_CLOSE_MODAL:
      return Object.assign({}, state, {
        sourceArticlesModalOpen: false
      })

    case SOURCE_ARTICLES_RESET_MODAL:
      return Object.assign({}, state, {
        selectedSourceModal: null,
        selectedSourceArticlesModal: [],
        sourceError: null,
        fetchingSourceArticlesModal: false
      })

    case FETCHING_SOURCE_ARTICLES_MODAL:
      return Object.assign({}, state, {
        fetchingSourceArticlesModal: true
      })

    case FETCH_SOURCE_ARTICLES_MODAL_SUCCESS:
      return Object.assign({}, state, {
        fetchingSourceArticlesModal: false,
        selectedSourceArticlesModal: action.sourceArticles
      })

    case FETCH_SOURCE_ARTICLES_MODAL_ERROR:
      return Object.assign({}, state, {
        sourceError: action.error,
        fetchingSourceArticlesModal: false,
        selectedSourceArticlesModal: []
      })

    case FETCHING_SOURCE_ARTICLES:
      return Object.assign({}, state, {
        fetchingSourceArticles: true,
        selectedSource: action.source
      })

    case FETCH_SOURCE_ARTICLES_SUCCESS:
      return Object.assign({}, state, {
        fetchingSourceArticles: false,
        selectedSourceArticles: action.sourceArticles,
        articlesPage: 1
      })

    case FETCH_SOURCE_ARTICLES_ERROR:
      return Object.assign({}, state, {
        selectedSource: null,
        fetchingSourceArticles: false,
        selectedSourceArticles: []
      })

    case FETCHING_MORE_SOURCE_ARTICLES:
      return Object.assign({}, state, {
        fetchingMoreSourceArticles: true
      })

    case FETCH_MORE_SOURCE_ARTICLES_SUCCESS:
      return Object.assign({}, state, {
        fetchingMoreSourceArticles: false,
        selectedSourceArticles: [...state.selectedSourceArticles, ...action.sourceArticles],
        articlesPage: action.page
      })

    case SAVE_SETTINGS_SUCCESS:
      const updatedFeedsSettings = state.feeds.map(feed => {
        if (state.id === feed.id) {
          feed.name = action.name
          feed.config = action.config
        }
        return feed
      })
      const updatedSelectedFeedSettings = state.selectedFeed
      updatedSelectedFeedSettings.name = action.name
      updatedSelectedFeedSettings.config = action.config
      return Object.assign({}, state, {
        feeds: updatedFeedsSettings,
        selectedFeed: updatedSelectedFeedSettings,
        refreshArticles: true
      })

    case SEARCH_USERS:
      return Object.assign({}, state, {
        users: action.users.map((user) => [user, `${user.first_name} ${user.last_name}`])
      })

    case TOGGLE_SAVE_ARTICLE:
      return Object.assign({}, state, {
        articles: state.articles.map((article) => {
          if (article.id === action.articleId) {
            article.saved = !article.saved
          }
          return article
        }),
        selectedArticle: state.selectedArticle && Object.assign({}, state.selectedArticle, {
          saved: state.selectedArticle.id === action.articleId
            ? !state.selectedArticle.saved
            : state.selectedArticle.saved
        })
      })

    default:
      return state
  }
}

export default feedReducer

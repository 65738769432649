import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { MenuItem, Menu, Avatar } from '@material-ui/core'
import { connect } from 'react-redux'
import { getProfile, logout } from '../../../../actions/authActions'
import { makeStyles } from '@material-ui/styles'
import history from '../../../../history'

const mapStateToProps = state => ({
  loggedIn: state.auth.loggedIn,
  firstName: state.auth.firstName,
  lastName: state.auth.lastName
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProfile,
      logout
    },
    dispatch
  )

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(1)
  },
  avatar: {
    cursor: 'pointer',
    width: 50,
    height: 50,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}))

const User = ({ firstName, lastName, loggedIn, logout, getProfile }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    if (!loggedIn) {
      getProfile()
    }
  }, [loggedIn])

  if (!loggedIn) {
    return <React.Fragment />
  }
  return (
    <div className={classes.wrapper}>
      <Avatar className={classes.avatar} onClick={({ currentTarget }) => setAnchorEl(currentTarget)}>
        {firstName && firstName[0]}
        {lastName && lastName[0]}
      </Avatar>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        {/* <MenuItem onClick={() => history.push('/settings')}>Profile</MenuItem> */}
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
      {/* <Notifications /> */}
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(User)

export const TITLE_ONLY_VIEW = 'TITLE_ONLY_VIEW'
export const MAGAZINE_VIEW = 'MAGAZINE_VIEW'
export const ARTICLE_VIEW = 'ARTICLE_VIEW'
export const SOURCE_VIEW = 'SOURCE_VIEW'

/**
 * Messages
 */

export const ERROR_FILL_ALL_FIELDS = 'Please fill in all fields'
export const ERROR_INVALID_EMAIL = 'Invalid email address'
export const ERROR_PASSWORDS_DONT_MATCH = 'Password and Password confirmation must match'
export const ERROR_PASSWORD_TOO_SHORT = 'Password should be at least 8 characters'
export const ERROR_ALREADY_REGISTERED = 'Email already registered.'
export const ERROR_WRONG_EMAILPW = 'Wrong username or password'
export const ERROR_OTHER_ERRORS = 'An error ocurred.'
export const ERROR_ON_VERIFY_EMAIL = 'An error ocurred while verifying your email.'

/**
 * Pages
 */

export const FEED = 'FEED'
export const MY_FEED = 'MY_FEED'
export const TODAY = 'TODAY'
export const ARTICLE = 'ARTICLE'
export const NOTIFICATIONS = 'NOTIFICATIONS'
export const TRANSCRIBE = 'TRANSCRIBE_ARTICLE'
export const SEARCH = 'SEARCH_RESULTS'
export const USER_SETTINGS = 'USER_SETTINGS'
export const TRANSCRIPTION = 'TRANSCRIPTION'
export const TRANSCRIPTIONS = 'TRANSCRIPTIONS'
export const GENERATE = 'GENERATE'

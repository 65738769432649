import { MAIN_ONBOARDING_READY } from "../actions/types";

const onBoardingInitialState = {
  mainOnBoardingReady: false
};

const onBoardingReducer = function(state = onBoardingInitialState, action) {
  switch (action.type) {
    case MAIN_ONBOARDING_READY:
      return Object.assign({}, state, {
        mainOnBoardingReady: true
      });
    default:
      return state;
  }
};

export default onBoardingReducer;

import {
  SET_MENU_TITLE,
  SET_PAGE,
  SET_TAB,
  SET_MODAL_OPEN,
  UPLOAD_FILE_OPEN_MODAL,
  UPLOAD_FILE_CLOSE_MODAL,
  UPLOAD_FILE_START,
  UPLOAD_FILE_ERROR,
  UPLOAD_PROGRESS
} from './types'

export const setMenuTitle = title => {
  return {
    type: SET_MENU_TITLE,
    title
  }
}

export const setPage = page => {
  return {
    type: SET_PAGE,
    page
  }
}

export const setTab = tab => {
  return {
    type: SET_TAB,
    tab
  }
}

export const setIsModalOpen = (isOpen) => {
  return {
    type: SET_MODAL_OPEN,
    isOpen
  }
}

export const uploadFileOpenModal = () => {
  return {
    type: UPLOAD_FILE_OPEN_MODAL
  }
}

export const uploadFileCloseModal = () => {
  return {
    type: UPLOAD_FILE_CLOSE_MODAL
  }
}

export const uploadFileStart = () => {
  return {
    type: UPLOAD_FILE_START
  }
}

export const uploadFileError = (error) => {
  return {
    type: UPLOAD_FILE_ERROR,
    error
  }
}

export const fileUploadProgress = (progress) => {
  return {
    type: UPLOAD_PROGRESS,
    progress
  }
}

import { SIGNUP_FAILURE, LOGIN_FAILURE, SIGNUP_FAILURE_HIDE_ERROR, LOGIN_SUCCESS, LOGOUT, VERIFY_SUCCESS, SAVE_USER_INFO, RECOVERED_PASSWORD, RESETTED_PASSWORD } from './types'
import { deleteProfile, deleteToken, loadProfile, saveProfile, saveToken } from './../localStorage'
import { BASE_URL } from '../config'
import { post } from '../services'
import {
  ERROR_FILL_ALL_FIELDS,
  ERROR_INVALID_EMAIL,
  ERROR_ON_VERIFY_EMAIL
} from '../consts'
import history from '../history'

/*
 * Auth Actions
 */

export const createAccount = (creds) => dispatch => {
  post(
    `${BASE_URL}/user/create`,
    creds,
    dispatch,
    json => {
      saveToken(json.token)
      saveProfile(json)
      dispatch(loginSuccess(json))
      history.push('/')
    },
    error => {
      console.log('Error creating User', error)
    },
    false
  )
}

export const createUser = (email) => dispatch => {
  post(
    `${BASE_URL}/user`,
    {
      email
    },
    dispatch,
    () => {},
    error => {}, 
    false
  )
}

export const recoverPassword = (email) => dispatch => {
  post(
    `${BASE_URL}/user/recover`,
    {
      email
    },
    dispatch,
    () => {},
    error => {}, 
    false
  )
}

export const resetPassword = (password, token) => dispatch => {
  post(
    `${BASE_URL}/user/reset`,
    {
      confirmation_code: token,
      password
    },
    dispatch,
    () => {
      history.push('/')
    },
    error => {
      console.log('Error reseting password', error)
    },
    false
  )
}

export const login = (email, password) => dispatch => {
  let creds = {
    email,
    password
  }
  if (!(creds.email && creds.password)) {
    dispatch(loginFailure(ERROR_FILL_ALL_FIELDS))
  } else if (
    creds.email.indexOf('@') === -1 ||
    creds.email.indexOf('.') === -1
  ) {
    dispatch(loginFailure(ERROR_INVALID_EMAIL))
  } else {
    post(
      `${BASE_URL}/user/auth`,
      creds,
      dispatch,
      json => {
        saveToken(json.token)
        saveProfile(json)
        dispatch(loginSuccess(json))
      },
      error => {
        if (error.message === 'Forbidden') {
          dispatch(loginFailure(ERROR_ON_VERIFY_EMAIL))
        } else {
          dispatch(loginFailure('Invalid Email or Password'))
        }
      },
      false
    )
  }
}

export const logout = () => (dispatch) => {
  deleteProfile()
  deleteToken()
  dispatch(logOut())
}

export const getProfile = () => (dispatch) => {
  const profile = loadProfile()
  if (profile) {
    dispatch(loginSuccess(profile))
  } else {
    dispatch(logOut())
  }
}

export const signupFailure = (error_message) => {
  return {
    type: SIGNUP_FAILURE,
    errorMessage: error_message
  }
}

export const loginFailure = (errorMessage) => {
  return {
    type: LOGIN_FAILURE,
    errorMessage: errorMessage
  }
}

export const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS
  }
}

export const signupFailureHideError = () => {
  return {
    type: SIGNUP_FAILURE_HIDE_ERROR
  }
}

export const loginSuccess = (response) => {
  window.Beacon('identify', {
    name: `${response.first_name} ${response.last_name}`,
    email: response.email,
    phone: response.phone_number,
    clientId: response.client_id,
    signature: response.beaconToken
  })
  return {
    type: LOGIN_SUCCESS,
    token: response.token,
    feeds: response.feeds,
    firstName: response.first_name,
    lastName: response.last_name,
    email: response.email
  }
}

export const recoveredPassword = () => {
  return {
    type: RECOVERED_PASSWORD
  }
}

export const resettedPassword = () => {
  return {
    type: RESETTED_PASSWORD
  }
}

export const logOut = () => {
  return {
    type: LOGOUT
  }
}

export const saveUserInfo = (info) => {
  return {
    type: SAVE_USER_INFO,
    info: info
  }
}

import React, { useState } from 'react'
import {
    TRANSLATE_INPUT_LANGS,
    TRANSLATE_TARGET_LANGS,
    SUMMARIZE_INPUT_LANGS,
    TRANSFORMATIONS
} from '../../config'
import {
    Paper,
    Button,
    NativeSelect,
    FormControl,
    InputLabel,
    Tabs,
    Tab,
    Typography,
    Box,
    Slider
} from '@material-ui/core'



const Transformation = ({ input, token, translate, summarize, findEntities, classes }) => {
    const [inputLang, setInputLang] = useState('pt')
    const [targetLang, setTargetLang] = useState('en')
    const [value, setValue] = useState(0)
    const [sentences, setSentences] = useState(5)

    let options
    switch (value) {
        case TRANSFORMATIONS.TRANSLATE:
            options = (
                <div className={classes.run}>
                    <LangPicker
                        languages={TRANSLATE_INPUT_LANGS}
                        onChange={setInputLang}
                        value={inputLang}
                        label="Input Language"
                        name="Input Language"
                        id="input-language"
                        classes={classes}
                    />
                    <LangPicker
                        languages={TRANSLATE_TARGET_LANGS}
                        onChange={setTargetLang}
                        value={targetLang}
                        label="Target Language"
                        name="Target Language"
                        id="target-language"
                        classes={classes}
                    />
                    <Button
                        variant='contained'
                        onClick={() => { translate(input, inputLang, targetLang) }}
                    >
                        Translate
            </Button>
                </div >
            )
            break
        case TRANSFORMATIONS.SUMMARIZE:
            options = (
                // <div className={classes.run}>
                //     <LangPicker
                //         languages={SUMMARIZE_INPUT_LANGS}
                //         onChange={setInputLang}
                //         value={inputLang}
                //         label="Input Language"
                //         name="Input Language"
                //         id="input-language"
                //         classes={classes}
                //     />
                //     <FormControl className={classes.formControl}>
                //         <InputLabel htmlFor='sentences-slider'>Number of Sentences</InputLabel>
                //         <Slider
                //             id='sentences-slider'
                //             className={classes.slider}
                //             defaultValue={5}
                //             aria-labelledby='discrete-slider'
                //             valueLabelDisplay='auto'
                //             step={1}
                //             marks
                //             min={1}
                //             max={20}
                //             onChange={(event, value) => { setSentences(value) }}
                //         />
                //     </FormControl>
                //     <Button
                //         variant='contained'
                //         onClick={() => { summarize(input, inputLang, sentences) }}
                //     >
                //         Summarize
                //     </Button>
                // </div>
                <div className={classes.run}>
                    Summarization is disabled for maintenance 🛠
                </div >
            )
            break
        case TRANSFORMATIONS.FIND_ENTITIES:
            options = (
                // <div className={classes.run}>
                //     <Button
                //         variant='contained'
                //         onClick={() => { findEntities(input, token) }}
                //     >
                //         Find Entities
                //     </Button>
                // </div >
                <div className={classes.run}>
                    Finding Entities is disabled for maintenance 🛠
                </div >
            )
            break
        default:
            options = <p>Select a Transformation to show its options</p>
    }
    return (
        <Paper className={classes.root}>
            <Tabs
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue)
                }}
                indicatorColor='primary'
                textColor='primary'
                centered
            >
                <Tab label='Translate' />
                <Tab label='Summarize' />
                <Tab label='Find Entities' />
            </Tabs>
            <TabPanel value={value} index={0}>
                {options}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {options}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {options}
            </TabPanel>
        </Paper>
    )
}

const LangPicker = ({ languages, onChange, value, label, name, id, classes }) => {
    const options = languages.map((language) => {
        return <option id={language.code} value={language.code}>{language.name}</option>
    })

    return (
        <FormControl className={classes.formControl}>
            <InputLabel htmlFor='input-language'>{label}</InputLabel>
            <NativeSelect
                value={value}
                onChange={(event) => { onChange(event.target.value) }}
                inputProps={{
                    name,
                    id
                }}
            >
                {options}
            </NativeSelect>
        </FormControl>
    )
}

function TabPanel(props) {
    const { children, value, index, options, ...other } = props

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    )
}

export default Transformation

import { createMuiTheme } from '@material-ui/core'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#198cff',
      contrastThreshold: 0
    },
    secondary: {
      main: '#385cca'
    },
    green: {
      main: '#3ec5a8',
      contrastText: '#fff'
    },
    orange: {
      main: '#ff6700',
      contrastText: '#fff'
    },
    error: {
      main: '#d8384a'
    },
    white: {
      main: '#ffffff'
    }
  },
  spacing: 13,
  background: '#ecf6ff'
})

export default theme

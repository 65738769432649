import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { getTranscription, updateTranscription } from '../../../actions/transcriptionsActions'
import TranscriptEditor from 'react-transcript-editor/dist'
import Loading from '../../common/Loading'
import ButtonIcon from '../../common/ButtonIcon'
import history from '../../../history'
import { makeStyles } from '@material-ui/styles'
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg'
import {
  Button,
  FormControl,
  InputLabel,
  NativeSelect,
  LinearProgress,
  Typography,
  Card,
  CardContent,
  CardActions
} from '@material-ui/core'
import { post } from '../../../services'
import { BASE_URL } from '../../../config'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
  topBar: {
    display: 'flex',
    flexBasis: '100%',
    flexDirection: 'row',
    maxHeight: theme.spacing(6),
    padding: theme.spacing(1)
  },
  actions: {
    display: 'flex',
    paddingLeft: theme.spacing(1)
  },
  segmentContainer: {
    display: 'flex',
    position: 'fixed',
    bottom: theme.spacing(4),
    left: theme.spacing(2),
    flexDirection: 'column',
    backgroundColor: 'white',
    borderStyle: 'rounded'
  },
  status: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  formControl: {
    minWidth: 200
  },
  segments: {
    margin: 0,
    padding: 0
  }
}))

const mapStateToProps = state => ({
  transcription: state.transcriptions && state.transcriptions.transcription,
  loading: state.transcriptions && state.transcriptions.loading,
  updateTrancriptionError: state.transcriptions && state.transcriptions.updateTrancriptionError
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTranscription,
      updateTranscription
    },
    dispatch
  )

const Transcription = ({ getTranscription, updateTranscription, match, transcription, loading, updateTrancriptionError }) => {
  const classes = useStyles()

  const clipPadding = 0.2;
  const isTextEditable = true
  const spellCheck = true
  const transcriptionId = match.params.transcriptionId
  const transcriptEditorRef = React.createRef()
  const [inputLang, setInputLang] = useState('pt')
  const [targetLang, setTargetLang] = useState('en')
  const [segments, setSegments] = useState([])
  const [isGeneratingClip, setIsGeneratingClip] = useState(false)

  useEffect(() => {
    getTranscription(transcriptionId)
    const hasSeen = localStorage.getItem('oko_clips')
    if (hasSeen !== 'true') {
      setTimeout(() => {
        window.Beacon('article', '601e0ef1a4cefb30ae5c80f9')
      }, 3000);
      localStorage.setItem('oko_clips', 'true')
    }
  }, [])

  const handleAutoSaveChanges = newAutoSaveData => {
    updateTranscription(transcription.id, newAutoSaveData)
  }

  const generateSegment = async () => {
    try {
      const getCurrentSelection = transcriptEditorRef.current.getCurrentSelection
      let { startTime, endTime } = getCurrentSelection()
      startTime = startTime - clipPadding
      startTime = startTime >= 0 ? startTime : 0
      endTime = endTime + clipPadding

      if (startTime >= endTime) {
        throw new Error("Invalid time range")
      }
      setSegments([...segments, { startTime, endTime }])

    } catch (error) {
      alert('Error Generating Segment')
      console.log(error)
    }
  }

  const generateClip = () => {
    const download = transcriptEditorRef.current.download
    setIsGeneratingClip(true)
    post(
      `${BASE_URL}/clip/`,
      { ranges: segments, fileId: transcription.file_id },
      null,
      json => {
        const link = document.createElement("a")
        link.href = json.clipUrl
        // Firefox fix - cannot do link.click() if it's not attached to DOM in firefox
        // https://stackoverflow.com/questions/32225904/programmatical-click-on-a-tag-not-working-in-firefox
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setIsGeneratingClip(false)
      },
      error => {
        setIsGeneratingClip(false)
        console.log('Error creating clip', error)
      },
      false
    )
  }

  const handleSendNotepad = () => {
    const { data } = transcriptEditorRef.current.getEditorContent()
    const location = {
      pathname: '/notepad',
      state: {
        fromTranscription: true,
        draftJs: data
      }
    }
    history.push(location)
  }

  const handleDownload = (filename) => {
    const { data } = transcriptEditorRef.current.getEditorContent('srt')
    post(
      `${BASE_URL}/notepad/translate`,
      { input: data, targetLang, inputLang },
      null,
      json => {
        transcriptEditorRef.current.download(json.result, `${filename}.srt`)
      },
      error => {
        console.log('error Translate', error)
      },
      false
    )
  }

  if (!transcription || !transcription.result.originalResponse) {
    return (
      <Loading />
    )
  }
  const SegmentList = () => {
    if (segments.length === 0) {
      return null
    }
    return segments.map((segment) => {
      let startDate = new Date(0)
      startDate.setSeconds(segment.startTime)
      let startTime = startDate.toISOString().substr(11, 8)
      let endDate = new Date(0)
      endDate.setSeconds(segment.endTime)
      let endTime = endDate.toISOString().substr(11, 8)
      return (
        <span key={`${startTime}${endTime}`}>
          <Typography>{startTime} - {endTime}</Typography>
        </span>
      )
    })
  }

  const transcriptData = transcription.edited
    ? transcription.edition.data
    : transcription.result.originalResponse

  const jsonType = transcription.edited
    ? 'draftjs'
    : 'trint'

  return (
    <div>
      <div className={classes.topBar}>
        <ButtonIcon
          className={classes.return}
          tooltip='Back'
          onClick={() => history.push('/transcriptions')}
          icon='back'
          color='secondary'
        >
          Return to Transcription List
        </ButtonIcon>
        <div className={classes.actions}>
          <Button variant='contained' color='primary' onClick={handleSendNotepad}>
            Send to Notepad
          </Button>
        </div>
        {/* <div className={classes.actions}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor='input-language'>Input Language</InputLabel>
            <NativeSelect
              value={inputLang}
              onChange={(event) => { setInputLang(event.target.value) }}
              inputProps={{
                name: 'inputLang',
                id: 'input-language'
              }}
            >
              <option value='cs'>Czech</option>
              <option value='en'>English</option>
              <option value='fr'>French</option>
              <option value='it'>Italian</option>
              <option value='pt'>Portuguese</option>
              <option value='es'>Spanish</option>
            </NativeSelect>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor='target-language'>Output Language</InputLabel>
            <NativeSelect
              value={targetLang}
              onChange={(event) => { setTargetLang(event.target.value) }}
              inputProps={{
                name: 'targetLang',
                id: 'target-language'
              }}
            >
              <option value='cs'>Czech</option>
              <option value='en'>English</option>
              <option value='fr'>French</option>
              <option value='it'>Italian</option>
              <option value='pt'>Portuguese</option>
              <option value='es'>Spanish</option>
            </NativeSelect>
          </FormControl >
          <Button variant='contained' color='primary' onClick={() => handleDownload(transcription.name.split('.')[0])}>
            Translate and Download Subtitles
          </Button>
        </div> */}
        <div className={classes.status}>
          {updateTrancriptionError || loading ? 'Saving your changes...' : ''}
        </div>
      </div>

      <TranscriptEditor
        transcriptData={transcriptData}
        mediaUrl={transcription.url}
        isEditable={isTextEditable}
        spellCheck={spellCheck}
        sttJsonType={jsonType}
        title={transcription.name.split(`.${transcription.file_extension}`)[0]}
        ref={transcriptEditorRef}
        handleAutoSaveChanges={handleAutoSaveChanges}
        autoSaveContentType={'draftjs'}
      />
      {transcription.file_extension.toLowerCase() === 'mp3' && 
      <Card className={classes.segmentContainer} variant="outlined">
        <CardContent>
          <Typography>{segments.length > 0 ? 'Your segments:' : 'Create your first segment'}</Typography>
          <ul className={classes.segments}>
            <SegmentList />
          </ul>
        </CardContent>
        <CardActions>
          <Button 
            size="small"
            color='primary'
            onClick={generateSegment}
            disabled={isGeneratingClip}
          >
            Create Segment
          </Button>
          <Button
            size="small"
            color='primary'
            onClick={generateClip}
            disabled={isGeneratingClip}
          >
            {isGeneratingClip ? 'Please Wait' : 'Generate Clip'}
          </Button>
        </CardActions>
        <CardActions>
          <Button 
            size="small"
            color='secondary'
            onClick={() => setSegments([])}
            disabled={isGeneratingClip}
          >
            Reset Segments
          </Button>
        </CardActions>
      </Card> }

    </div>

  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Transcription))

import React from 'react'
import {Button, Tooltip} from '@material-ui/core'
import SvgIcon from './SvgIcon'

const ButtonIcon = props => {
  const {icon, tooltip, children} = props

  return (
    <Tooltip title={tooltip}>
      <Button {...props}>
        <SvgIcon icon={icon} />
        {children}
      </Button>
    </Tooltip>
  )
}

export default ButtonIcon

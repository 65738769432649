import React, {Component} from 'react'
import Loading from 'react-loader-spinner'

class LoadingOval extends Component {
  render () {
    return (
      <div className='loading'>
        <Loading type='Oval' width={this.props.width} height={this.props.height} color='#198cff' />
      </div>
    )
  }
}

LoadingOval.defaultProps = {
  width: 100,
  height: 100
}

export default LoadingOval

import * as types from '../actions/types'

const initialRelatedState = {
  articles: [],
  parent: {}
}

const relatedReducer = function (state = initialRelatedState, action) {
  switch (action.type) {
    case types.FETCH_RELATED_SUCCESS:
      return Object.assign({}, state, {
        articles: action.articles,
        parent: action.parent
      })
    default: return state
  }
}

export default relatedReducer

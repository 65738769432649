export const loadState = () => {
  try {
    let serializedState = localStorage.getItem('state')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (error) {
    console.log(error)
    return undefined
  }
}

export const saveState = (state) => {
  try {
    let serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
  } catch (error) {
    console.log(error)
  }
}

export const saveProfile = (profile) => {
  console.log(profile)
  try {
    localStorage.setItem('profile', JSON.stringify(profile))
  } catch (error) {
    console.log(error)
  }
}

export const loadProfile = () => {
  try {
    let profile = localStorage.getItem('profile')
    if (profile === null) {
      return undefined
    }
    return JSON.parse(profile)
  } catch (error) {
    console.log(error)
  }
}

export const deleteProfile = () => {
  localStorage.removeItem('profile')
}

export const saveToken = (token) => {
  console.log(token)
  try {
    localStorage.setItem('token', token)
  } catch (error) {
    console.log(error)
  }
}

export const loadToken = () => {
  try {
    let token = localStorage.getItem('token')
    if (token === null) {
      return undefined
    }
    return token
  } catch (error) {
    console.log(error)
  }
}

export const deleteToken = () => {
  localStorage.removeItem('token')
}

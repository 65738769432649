import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Avatar,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Input,
  Typography,
  SnackbarContent
} from '@material-ui/core'
import { LockOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import Page from '../common/Page'
import { getProfile, login } from '../../actions/authActions'

const useStyles = makeStyles(theme => ({
  main: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  },
  snackbar: {
    marginTop: theme.spacing(2)
  }
}))

const mapStateToProps = state => ({
  loggedIn: state.auth && state.auth.loggedIn,
  loginFailed: state.auth && state.auth.loginFailed,
  errorMessage: state.auth && state.auth.errorMessage
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login,
      getProfile
    },
    dispatch
  )

const Login = ({ loggedIn, login, getProfile, location, loginFailed, errorMessage }) => {
  const from = location && location.state && location.state.from
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    if (!loggedIn) {
      getProfile()
    }
  }, [])

  if (loggedIn) {
    return from ? <Redirect to={from} /> : <Redirect to='/portal' />
  } else {
    return (
      <Page noTopBar>
        <main className={classes.main}>
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlined />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Sign in
            </Typography>
            <form
              className={classes.form}
              onSubmit={(event) => {
                login(email, password)
                event.preventDefault()
              }}
            >
              <FormControl margin='normal' required fullWidth>
                <InputLabel htmlFor='email'>Email Address</InputLabel>
                <Input
                  id='email'
                  name='email'
                  autoComplete='email'
                  onChange={(event) => {
                    setEmail(event.target.value)
                  }}
                  autoFocus
                />
              </FormControl>
              <FormControl margin='normal' required fullWidth>
                <InputLabel htmlFor='password'>Password</InputLabel>
                <Input
                  name='password'
                  type='password'
                  id='password'
                  autoComplete='current-password'
                  onChange={(event) => {
                    setPassword(event.target.value)
                  }}
                />
              </FormControl>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
              >
                Sign in
              </Button>
              <Link to={`/forgot-password`}>Recover your password</Link>
              <br/>
              <Link to={`/register`}>Create a new account</Link>
              {loginFailed && (
                <SnackbarContent
                  className={classes.snackbar}
                  message={errorMessage}
                />
              )}
            </form>
          </Paper>
        </main >
      </Page>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)

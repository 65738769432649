import {
  SET_MENU_TITLE,
  SET_PAGE,
  SET_TAB,
  SET_MODAL_OPEN,
  UPLOAD_FILE_OPEN_MODAL,
  UPLOAD_FILE_CLOSE_MODAL,
  UPLOAD_FILE_START,
  UPLOAD_FILE_ERROR,
  UPLOAD_PROGRESS
} from '../actions/types'
import {MY_FEED} from '../consts'

const initialMenuState = {
  title: '',
  page: MY_FEED,
  tab: 0,
  uploadFileModal: false,
  uploadFileStart: false,
  uploadFileError: null,
  uploadProgress: 0,
  isModalOpen: false
}

const menuReducer = function (state = initialMenuState, action) {
  switch (action.type) {
    case SET_MENU_TITLE:
      return Object.assign({}, state, {
        title: action.title
      })
    case SET_PAGE:
      return Object.assign({}, state, {
        page: action.page
      })
    case SET_TAB:
      return Object.assign({}, state, {
        tab: action.tab
      })
    case SET_MODAL_OPEN:
      return Object.assign({}, state, {
        isModalOpen: action.isOpen
      })
    case UPLOAD_FILE_OPEN_MODAL:
      return Object.assign({}, state, {
        uploadFileModal: true,
        uploadFileError: null,
        uploadFileStart: false,
        isModalOpen: true,
        uploadProgress: 0
      })
    case UPLOAD_FILE_CLOSE_MODAL:
      return Object.assign({}, state, {
        uploadFileModal: false
      })
    case UPLOAD_FILE_START:
      return Object.assign({}, state, {
        uploadFileStart: true
      })
    case UPLOAD_FILE_ERROR:
      return Object.assign({}, state, {
        uploadFileError: action.error,
        uploadFileStart: false
      })
    case UPLOAD_PROGRESS:
      return Object.assign({}, state, {
        uploadProgress: action.progress
      })
    default:
      return state
  }
}

export default menuReducer

import {loadToken, deleteProfile, deleteToken} from './localStorage'
import {logOut} from './actions/authActions'
import {errorToast} from './toasts'
import {BASE_URL} from './config'

function handleErrors (response, dispatch) {
  if (response.status >= 400) {
    if (response.status === 401) {
      deleteProfile()
      deleteToken()
      dispatch(logOut())
      throw Error('Unauthorized')
    } else if (response.status === 403) {
      deleteProfile()
      deleteToken()
      dispatch(logOut())
      throw Error('Forbidden')
    } else {
      throw Error('An error occured, please try again later')
    }
  }
  return response
}

export const get = (url, dispatch, successCallback, errorCallback, callToast = true) => {
  let request = new Request(url)
  let reqHeaders = new Headers({
    Authorization: 'Bearer ' + loadToken()
  })
  let config = {
    method: 'GET',
    headers: reqHeaders,
    cache: 'default',
    credentials: 'same-origin'
  }
  fetch(request, config)
    .then(response => handleErrors(response, dispatch))
    .then(response =>
      response.json().catch(_ => response)
    )
    .then(json => {
      successCallback(json)
    })
    .catch(error => {
      if (callToast) {
        errorToast(error.message)
      }
      errorCallback(error)
    })
}

export const post = (url, body, dispatch, successCallback, errorCallback, callToast = true) => {
  let request = new Request(url)
  let reqHeaders = new Headers({
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + loadToken()
  })
  let config = {
    method: 'POST',
    headers: reqHeaders,
    credentials: 'same-origin',
    cache: 'default',
    body: JSON.stringify(body)
  }
  fetch(request, config)
    .then(response => handleErrors(response, dispatch))
    .then(response => {
      return response.json().catch(_ => response)
    })
    .then(json => {
      successCallback(json)
    })
    .catch(error => {
      if (callToast !== false) {
        errorToast(error.message)
      }
      errorCallback(error)
    })
}

export const put = (url, body, dispatch, successCallback, errorCallback, callToast = true) => {
  let request = new Request(url)
  let reqHeaders = new Headers({
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + loadToken()
  })
  let config = {
    method: 'PUT',
    headers: reqHeaders,
    cache: 'default',
    body: JSON.stringify(body),
    credentials: 'same-origin'
  }
  fetch(request, config)
    .then(response => handleErrors(response, dispatch))
    .then(response => {
      return response.json().catch(_ => response)
    })
    .then(json => {
      successCallback(json)
    })
    .catch(error => {
      if (callToast !== false) {
        errorToast(error.message)
      }
      errorCallback(error)
    })
}

export const uploadFile = (file, language, dispatch, successCallback, errorCallback, progressCallback, callToast = true) => {
  const headers = {
    name: file.name,
    type: file.type.split('/')[0],
    language,
    Authorization: 'Bearer ' + loadToken()
  }
  const xhr = new XMLHttpRequest()
  xhr.open('POST', `${BASE_URL}/file`)
  for (const k in headers) {
    xhr.setRequestHeader(k, headers[k])
  }
  xhr.upload.onprogress = (e) => {
    const percentage = parseInt(100 - (e.loaded / e.total * 100), 10)
    if (progressCallback) {
      progressCallback(100 - percentage, e.loaded, e.total)
    }
  }
  xhr.onreadystatechange = (e) => {
    if (xhr.readyState === 4) {
      if (xhr.status >= 400) {
        handleErrors(xhr)
      }
      if (progressCallback) {
        progressCallback(100)
      }
      successCallback(xhr.responseText && JSON.parse(xhr.responseText))
    }
  }
  xhr.onerror = (error) => {
    if (callToast) {
      errorToast(error.message)
    }
    errorCallback(error)
  }
  xhr.send(file)
}

export const _delete = (
  url,
  body,
  dispatch,
  successCallback,
  errorCallback
) => {
  let request = new Request(url)
  let reqHeaders = new Headers({
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + loadToken()
  })
  let config = {
    method: 'DELETE',
    headers: reqHeaders,
    cache: 'default',
    body: body && JSON.stringify(body)
  }
  fetch(request, config)
    .then(response => handleErrors(response, dispatch))
    .then(response => {
      return response.json().catch(_ => response)
    })
    .then(json => {
      successCallback(json)
    })
    .catch(error => {
      errorToast(error.message)
      errorCallback(error)
    })
}
